import i18n from '@/i18n'
import { createSlice } from '@reduxjs/toolkit'
import { persistStore } from '@store/persistStore'
import { prefLanguageFromStore } from '@/services/storageServices'

interface ILanguage {
  current: LanguageKey
}

const initialState: ILanguage = {
  current: prefLanguageFromStore(),
}

export const languageSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAppLocale: (state, action) => {
      i18n.changeLanguage(action.payload, () => {
        state.current = action.payload
        persistStore.setItem('lang', action.payload)
      })
    },
  },
})

export default languageSlice.reducer
export const { setAppLocale } = languageSlice.actions
