import FormFieldElement from '@/components/UI/FormFieldElement'
import { FIRST_PAGE_TO_VISIT, PATHS } from '@/routes/paths'
import { useAppDispatch } from '@/store'
import { doAuth } from '@/store/slices/authSlice'
import { fetchEquipmentTypes } from '@/store/slices/prefetchDataSlice'
import Button from 'antd/es/button'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

const loginInputs: FormField<LoginData>[] = [
  {
    name: 'username',
    rules: [{ required: true }],
  },
  {
    name: 'password',
    input: { component: Input.Password },
    rules: [{ required: true }],
  },
]

const FormLogin = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [form] = Form.useForm<LoginData>()
  const navigate = useNavigate()

  const handleFormSubmit = async (data: LoginData) => {
    setIsLoading(true)
    try {
      // Authenticate user
      await dispatch(doAuth(data)).unwrap()

      // Fetch equipment types and store them in localStorage or sessionStorage
      await dispatch(fetchEquipmentTypes()).unwrap()

      // Redirect to the first page to visit
      navigate(FIRST_PAGE_TO_VISIT)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='mx-auto min-w-[500px] max-w-screen-sm p-8 px-12'>
      <Form form={form} layout='vertical' onFinish={handleFormSubmit} className='space-y-10'>
        {loginInputs.map((formField, index) => (
          <FormFieldElement key={index} {...formField} />
        ))}
        <div className='relative -top-5 flex justify-end'>
          <Link
            data-cy='recoverFormLink'
            className='text-xs text-brand underline'
            to={PATHS.AUTH.RECOVER_ACCOUNT_PATH}
          >
            {t('app.forgot_password')}
          </Link>
        </div>
        <Form.Item>
          <Button loading={isLoading} block type='primary' htmlType='submit'>
            {t('action.login')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default FormLogin
