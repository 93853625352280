import React, { lazy } from 'react'
import { PATHS } from './paths'
import { RoutesAccessType } from '@/utils/middlewares'
const PageSanitations = lazy(() => import('@/pages/sanitations/PageSanitations'))
const PageMonitoring = lazy(() => import('@/pages/monitorings/PageMonitoring'))
const PageLogout = lazy(() => import('@/pages/auth/PageLogout'))
const Dashboard = lazy(() => import('@/pages/dashboard/Dashboard'))
const PageMonitoringMeasures = lazy(() => import('@/pages/monitorings/PageMonitoringMeasures'))
const FormSanitation = lazy(() => import('@/pages/sanitations/FormSanitation'))
const PageIndex = lazy(() => import('@/pages/PageIndex'))
const PageUsers = lazy(() => import('@/pages/users/PageUsers'))
const PageSettings = lazy(() => import('@/pages/userProfil/PageSettings'))
const PageLaboResults = lazy(() => import('@/pages/laboResults/PageLaboResults'))
const PageForbidden = lazy(() => import('@/pages/error/PageForbidden'))
const PageNotFound = lazy(() => import('@/pages/error/PageNotFound'))

interface IRoutes {
  path: string
  component: React.FC
  access: RoutesAccessType
}

const Pages: IRoutes[] = [
  {
    path: '/',
    component: PageIndex,
    access: {
      permissions: [],
    },
  },
  /***************DASHBOARD_PROJECT_MANAGER AND CUSTOMER******************/
  {
    path: PATHS.DASHBOARD_PROJECT_MANAGER.LINK(),
    component: Dashboard,
    access: PATHS.DASHBOARD_PROJECT_MANAGER.ACCESS,
  },
  {
    path: PATHS.DASHBOARD_CLIENT.LINK(),
    component: Dashboard,
    access: PATHS.DASHBOARD_CLIENT.ACCESS,
  },
  /***************SETTINGS ROUTES***************** */
  {
    path: PATHS.SETTING,
    component: PageSettings,
    access: {
      permissions: [],
    },
  },
  /***************SANITATIONS ROUTES***************** */
  {
    path: PATHS.SANITATIONS.IN_PROGRESS_LIST.LINK,
    component: PageSanitations,
    access: PATHS.SANITATIONS.IN_PROGRESS_LIST.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.COMPLETED_LIST.LINK,
    component: PageSanitations,
    access: PATHS.SANITATIONS.COMPLETED_LIST.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.CREATE.LINK,
    component: FormSanitation,
    access: PATHS.SANITATIONS.CREATE.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.UPDATE.LINK(),
    component: FormSanitation,
    access: PATHS.SANITATIONS.UPDATE.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.UPDATE.LINK2(),
    component: FormSanitation,
    access: PATHS.SANITATIONS.UPDATE.ACCESS,
  },
  /***************SANITATIONS MONITORINGS ROUTES***************** */
  {
    path: PATHS.SANITATIONS.MONITORING.LINK(),
    component: PageMonitoring,
    access: PATHS.SANITATIONS.MONITORING.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.MONITORING.LINK2(),
    component: PageMonitoring,
    access: PATHS.SANITATIONS.MONITORING.ACCESS,
  },
  /***************SANITATIONS MONITORINGS MEASURES ROUTES***************** */
  {
    path: PATHS.SANITATIONS.MEASURES.LINK(),
    component: PageMonitoringMeasures,
    access: PATHS.SANITATIONS.MEASURES.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.MEASURES.LINK2(),
    component: PageMonitoringMeasures,
    access: PATHS.SANITATIONS.MONITORING.ACCESS,
  },
  /***************SANITATIONS LABO_RESULTS ROUTES***************** */
  {
    path: PATHS.SANITATIONS.LABO_RESULTS.LINK(),
    component: PageLaboResults,
    access: PATHS.SANITATIONS.LABO_RESULTS.ACCESS,
  },
  {
    path: PATHS.SANITATIONS.LABO_RESULTS.LINK2(),
    component: PageLaboResults,
    access: PATHS.SANITATIONS.LABO_RESULTS.ACCESS,
  },
  /***************USERS ROUTES***************** */
  {
    path: PATHS.USERS.LIST.LINK,
    component: PageUsers,
    access: PATHS.USERS.LIST.ACCESS,
  },
  /***************AUTH ROUTES***************** */
  {
    path: PATHS.AUTH.LOGOUT,
    component: PageLogout,
    access: {
      permissions: [],
    },
  },
  {
    path: PATHS.REDIRECT.FORBIDDEN,
    component: PageForbidden,
    access: {
      permissions: [],
    },
  },
  {
    path: PATHS.REDIRECT.RESSOURCENOTFOUND,
    component: PageNotFound,
    access: {
      permissions: [],
    },
  },
]

export default Pages
