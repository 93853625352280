import { SetStateAction } from 'react'
import { Key, TableRowSelection } from 'antd/es/table/interface'
import i18next, { TFunction } from 'i18next'
import SorterIcons from '@/components/UI/SorterIcons'
import { FormInstance } from 'antd/es/form'
import Modal from 'antd/es/modal'
import Upload, { RcFile } from 'antd/es/upload'
import message from 'antd/es/message'
import {
  EDITABLE_CELL_WIDTH,
  EQUIPMENTS_CATEGORIES,
  FILE_CATEGORY,
  MEASURE_POSITION,
  MEASURE_SORTS_TYPES,
  MENU_ITEM_TYPES,
  MONITORING_MEASURE_SECTIONS,
  PARAMS_PRECISION_BY_ID,
  TABLE_PAGE_SIZES,
} from '@/constants'
import isNumber from 'lodash/isNumber'
import orderBy from 'lodash/orderBy'
import { patchSanitation } from '@/services/Sanitation'
import { deleteSanitationEquipmentGroup } from '@/services/sanitationGroupServices'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { readEquipmentTypes } from '@/services/storageServices'
import { createEquipmentData } from './formatter'
import { appConfig } from '@/configs/appConfig'
import { PayloadAction } from '@reduxjs/toolkit'
dayjs.extend(customParseFormat)

export const initDayJsDate = (date: string | number | Date) => dayjs(new Date(date))

/**
 * Clears field errors in a form instance.
 *
 * @param form - The form instance.
 * @returns A function to clear field errors.
 */
export const clearFieldErrors =
  <T extends Record<string, any>>(form: FormInstance<T>) =>
  (_changedValues: unknown, allValues: T) => {
    const updatedFields = Object.keys(allValues)
      .filter((name) => form.getFieldError(name).length)
      .map((name) => ({ name, errors: [] }))
    form.setFields(updatedFields)
  }

/**
 * Formats custom rules.
 *
 * @param t - The translation function.
 * @param customRules - Custom rules to format.
 * @returns Formatted custom rules.
 */
export const formatCustomRules = (
  t: TFunction<'translate', undefined>,
  customRules?: CustomRules,
) => {
  if (typeof customRules === 'function') return customRules(t)
  return customRules
}

/**
 * Compares two dates.
 *
 * @param a - The first date.
 * @param b - The second date.
 * @returns `true` if `a` is before `b`, otherwise `false`.
 */
export const compareDates = (a: string, b: string) => dayjs(a).isBefore(dayjs(b))

/**
 * Compares two numbers.
 *
 * @param a - The first number.
 * @param b - The second number.
 * @returns `true` if `a` is greater than `b`, otherwise `false`.
 */
export const compareNumbers = (a: number, b: number) => a > b

/**
 * Compares two strings.
 *
 * @param a - The first string.
 * @param b - The second string.
 * @returns A value less than 0 if `a` is sorted before `b`, 0 if `a` is equal to `b`,
 * or a value greater than 0 if `a` is sorted after `b`.
 */
export const compareStrings = (a: string, b: string) => a.localeCompare(b)

/**
 * Gets the full name of a user.
 *
 * @param user - The user object.
 * @returns The full name of the user.
 */
export const getUserFullName = (user: User) => `${user.first_name} ${user.last_name}`

/**
 * Compares columns based on their type for sorting.
 *
 * @param type - The type of the column.
 * @param key - The key of the column.
 * @returns A function to compare columns.
 */
export const compareColumns = (type: SortType, key: any) => {
  if (['boolean', 'number'].includes(type)) {
    return (a: Record<any, any>, b: Record<any, any>) => compareNumbers(a[key], b[key])
  }
  if (type === 'date') {
    return (a: Record<any, any>, b: Record<any, any>) => compareDates(a[key], b[key])
  }
  return (a: Record<any, any>, b: Record<any, any>) => compareStrings(a[key], b[key])
}

/**
 * Configures pagination options.
 *
 * @param pagination - Current pagination settings.
 * @param setPagination - Function to set pagination settings.
 * @param total - Total number of items.
 * @returns Pagination configuration.
 */
export const paginationConfig = (pagination: any, setPagination: any, total: number) => {
  return {
    pageSize: pagination.limit,
    total: total,
    pageSizeOptions: TABLE_PAGE_SIZES,
    onChange: (offset: number, limit: number) =>
      setPagination((prev: any) => ({ ...prev, offset: (offset - 1) * limit, limit })),
    responsive: true,
    showPrevNextJumpers: true,
    showSizeChanger: true,
  }
}

/**
 * Configures row selection settings.
 *
 * @param selectedRowKeys - Selected row keys.
 * @param setSelectedRowKeys - Function to set selected row keys.
 * @returns Row selection configuration.
 */
export const rowSelectionConfig = (
  selectedRowKeys: Key[],
  setSelectedRowKeys: React.Dispatch<SetStateAction<Key[]>>,
): TableRowSelection<any> => {
  return {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
    fixed: 'left',
  }
}

/**
 * Removes specified keys from an object.
 *
 * @param obj - The object.
 * @param keysToRemove - Keys to remove.
 * @returns The object with specified keys removed.
 */
export function removeKeys<T extends object, K extends keyof T>(
  obj: T,
  keysToRemove: K[],
): Omit<T, K> {
  const newObj = { ...obj }
  keysToRemove.forEach((key) => delete newObj[key])
  return newObj
}

/**
 * Sets specified keys to null in an object.
 *
 * @param obj - The object.
 * @param keysToSetNull - Keys to set to null.
 * @returns The object with specified keys set to null.
 */
export function nullateKeys<T extends object, K extends keyof T>(obj: T, keysToSetNull: K[]): T {
  const newObj = { ...obj }
  keysToSetNull.forEach((key) => {
    newObj[key] = null as T[K]
  })
  return newObj
}

/**
 * Picks specified keys from an object.
 *
 * @param obj - The object.
 * @param keysToSelect - Keys to select.
 * @returns An object with only the specified keys.
 */
export function pickFromObject<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keysToSelect: K[],
): Pick<T, K> {
  return Object.fromEntries(
    keysToSelect.filter((key) => key in obj).map((key) => [key, obj[key]]),
  ) as Pick<T, K>
}

/**
 * Parses headers for translation and sorting in a table.
 *
 * @param t - The translation function.
 * @param headers - Custom column headers.
 * @returns Parsed headers with translations and sorting configurations.
 */
export const parseHeaders = (
  t: TFunction<'tranlation', undefined>,
  headers: CustomColumnsType<any>,
) =>
  //@ts-ignore
  headers.map(({ title, sortAs, key, ...rest }) => {
    return {
      title: t(title as string),
      sortIcon: SorterIcons,
      ...(sortAs && { sorter: compareColumns(sortAs, key) }),
      ...rest,
    }
  })

/**
 * Converts an enumeration into an array of options with labels and values.
 *
 * @param enumeration - The enumeration to convert.
 * @param inputName - Name of the input.
 * @returns An array of options with labels and values.
 */
export const enumToChoices = <TEnum extends Record<string, any>>(
  enumeration: TEnum,
  inputName: string,
) => {
  const options: { label: string; value: string }[] = []
  Object.keys(enumeration || {}).forEach((value) =>
    options.push({
      label: `inputs.${inputName}.values.${value.toLocaleLowerCase()}`,
      value: enumeration[value as keyof typeof enumeration],
    }),
  )
  return options
}

/**
 * Creates a map for treatment units based on the provided array of associated equipment.
 * Each treatment unit ID serves as a key in the map, with corresponding values containing treatment unit information,
 * along with submaps for extractor/collector pairs and an array for recovery pumps.
 *
 * @param equipments - Array of AssociatedEquipment objects.
 * @returns A map where each key represents a treatment unit ID, and values contain treatment unit data.
 */
export function createTreatmentUnitMap(equipments: AssociatedEquipment[]) {
  const treatmentUnitMap = new Map<
    number,
    TreatmentUnit & {
      extractorCollector: Map<
        number,
        EquipmentGroup & {
          groupId: number
          extractor: BuildedSanitationEquipment
          collector: BuildedSanitationEquipment
        }
      >
      recoveryPumps: BuildedSanitationEquipment[]
    }
  >()

  equipments.forEach((equipment) => {
    const {
      treatment_unit,
      group,
      id: associationKey,
      equipment: { identifier, equipment_type, id, capacity, internal_diameter },
    } = equipment

    if (treatment_unit) {
      const unitKey = treatment_unit.id
      const unitData = treatmentUnitMap.get(unitKey) || {
        ...treatment_unit,
        extractorCollector: new Map<
          number,
          EquipmentGroup & {
            groupId: number
            extractor: BuildedSanitationEquipment
            collector: BuildedSanitationEquipment
          }
        >(),
        recoveryPumps: [],
      }
      const { extractor, collector } = readEquipmentTypes()
      const equipment = {
        identifier,
        id,
        equipment_type,
        capacity,
        internal_diameter,
        associationKey,
      }
      if (group) {
        const groupKey = group.id
        const groupData = unitData.extractorCollector.get(groupKey) || {
          ...group,
          groupId: groupKey,
          extractor: equipment,
          collector: equipment,
        }
        if (equipment_type === collector.id) groupData.collector = equipment
        if (equipment_type === extractor.id) groupData.extractor = equipment
        unitData.extractorCollector.set(groupKey, groupData)
      } else {
        unitData.recoveryPumps.push(equipment)
      }
      treatmentUnitMap.set(unitKey, unitData)
    }
  })

  return treatmentUnitMap
}

/**
 * Creates a map for measure wells based on the provided array of associated equipment.
 * Each measure well is grouped based on its associated group ID, and each group contains an array of measure wells.
 *
 * @param equipments - Array of AssociatedEquipment objects.
 * @returns A map where each key represents a group ID, and values contain measure wells grouped by the group ID.
 */
export function createMeasureWellMap(equipments: AssociatedEquipment[]) {
  const measureWellMap = new Map<
    number,
    {
      group: string
      groupId: number
      identifier: string
      measureWells: BuildedSanitationEquipment[]
    }
  >()
  equipments.forEach((equipment) => {
    const {
      group,
      treatment_unit,
      id: associationKey,
      equipment: { identifier, id, equipment_type, capacity, internal_diameter },
    } = equipment

    if (group && !treatment_unit) {
      const groupKey = group.id
      const groupData = measureWellMap.get(groupKey) || {
        ...group,
        groupId: group.id,
        group: group.number,
        identifier: group.number,
        measureWells: [],
      }
      groupData.measureWells.push({
        identifier,
        id,
        equipment_type: equipment_type,
        capacity,
        internal_diameter: internal_diameter,
        associationKey,
      } as never)
      measureWellMap.set(groupKey, groupData as any)
    }
  })

  return measureWellMap
}

/**
 * Creates a map for filters based on the provided array of associated equipment.
 * Filters are stored in the map with their IDs as keys.
 *
 * @param equipments - Array of AssociatedEquipment objects.
 * @returns A map where each key represents a filter ID, and values contain filter data.
 */
export function createFilterMap(equipments: AssociatedEquipment[]) {
  const filterMap = new Map<number, BuildedSanitationEquipment>()

  equipments.forEach((equipment) => {
    const {
      treatment_unit,
      group,
      id: associationKey,
      equipment: { identifier, id, equipment_type, capacity, internal_diameter },
    } = equipment
    const { air_filter, water_filter } = readEquipmentTypes()
    if (!treatment_unit && !group) {
      filterMap.set(id, {
        associationKey,
        identifier,
        id,
        internal_diameter,
        capacity,
        available_parameters: [],
        equipment_type: (equipment_type === air_filter.id
          ? air_filter.name
          : water_filter.name) as EQUIPMENTS_CATEGORIES,
      })
    }
  })

  return filterMap
}

/**
 * Map sanitation equipment data into separate equipment maps.
 *
 * This function takes a `Sanitation` object and maps its equipments into separate
 * maps for different equipment types such as treatment units, filters, and measure nodes groups.
 *
 * @param {Sanitation} sanitation - The `Sanitation` object containing equipment data.
 * @returns {object} An object with the following keys:
 *   - treatmentUnitMap: A map of treatment units, where each unit is associated with its ID.
 *   - filterMap: A map of filter units, where each unit is associated with its ID.
 *   - measureWellMap: A map of measure nodes, where each node is associated with its ID.
 */
export function sanitationEquipmentsMapper({ equipments }: Sanitation) {
  const treatmentUnitMap = createTreatmentUnitMap(equipments)
  const measureWellMap = createMeasureWellMap(equipments)
  const filterMap = createFilterMap(equipments)

  return { treatmentUnitMap, filterMap, measureWellMap }
}

/**
 * Builds data for updating equipment based on the provided array of builded sanitation equipment.
 * Each equipment's association key serves as a key in the resulting object, and the value contains equipment data.
 *
 * @param equipments - Array of BuildedSanitationEquipment objects.
 * @returns An object where each key represents an association key, and values contain equipment data.
 */
export function buildEquipmentUpdateData(equipments: BuildedSanitationEquipment[]) {
  const object = equipments.reduce(
    (result, equipment) => {
      if (equipment.id) {
        result[equipment.associationKey] = createEquipmentData(sanitizedEquipmentData(equipment))
      }
      return result
    },
    {} as Record<number, { equipment: EquipmentData }>,
  )
  return object
}

/**
 * Confirmation modal for delete operation.
 *
 * @param title - Title for the confirmation modal.
 * @param onConfirm - Function to execute on confirmation.
 * @param okText - Text for the confirmation button.
 * @param cancelText - Text for the cancel button.
 */
export const confirmDelete = ({
  title,
  onConfirm,
  okText,
  cancelText,
}: {
  title: string
  onConfirm: () => void
  okText?: string
  cancelText?: string
}) => {
  Modal.confirm({
    title: title,
    onOk: onConfirm,
    cancelText,
    okText,
    okButtonProps: {
      className: 'btn-danger',
    },
    onCancel() {},
  })
}

/**
 * Combines two objects.
 *
 * @param a - Object A.
 * @returns A function that takes an object B and returns a combined object of A and B.
 */
export function combineObjects<A extends Record<any, any>, B extends Record<any, any>>(a: A) {
  return (b: B) => ({ ...b, ...a })
}

/**
 * Builds a function to remove sanitationequipment.
 *
 * @param remove - Function to remove equipment.
 * @param worksite_sanitation - Worksites sanitation ID.
 * @param options - Optional object containing updater functions.
 * @returns An asynchronous function to remove sanitation equipment.
 */
export const buildEquipmentSanitationRemover =
  <T>(
    remove: (key: T) => void,
    worksite_sanitation: number,
    options?: { groupUpdater?: (id: number) => void; updater?: () => void },
  ) =>
  async (key: T, id: number) => {
    if (isNumber(id)) {
      await patchSanitation(worksite_sanitation, {
        equipments: {
          remove: [id],
          create: [],
        },
      })
      if (options) {
        const { updater, groupUpdater } = options
        if (updater) updater()
        if (groupUpdater) groupUpdater(id)
      }
      remove(key)
    } else remove(key)
  }

/**
 * Builds a function to remove equipment group on sanitation.
 *
 * @param remove - Function to remove equipment group.
 * @param worksite_sanitation - Worksites sanitation ID.
 * @param options - Optional object containing updater functions.
 * @returns An asynchronous function to remove equipment group.
 */
export const buildEquipmentGroupSanitationRemover =
  <T>(
    remove: (key: T) => void,
    worksite_sanitation: number,
    options?: { groupUpdater?: (id: number) => void; updater?: (key?: T) => void },
  ) =>
  async (key: T, id: number) => {
    if (isNumber(id)) {
      await deleteSanitationEquipmentGroup(worksite_sanitation, id)
      options?.groupUpdater?.(id)
    }
    options?.updater?.(key)
    remove(key)
  }

/**
 * Sanitizes equipment data by picking specific fields from the provided builded sanitation equipment.
 *
 * @param equipment - BuildedSanitationEquipment object.
 * @returns Sanitized equipment data.
 */
export const sanitizedEquipmentData = (equipment: BuildedSanitationEquipment) =>
  pickFromObject(equipment, [
    'identifier',
    'id',
    'equipment_type',
    'group',
    'treatment_unit',
    'internal_diameter',
    'capacity',
    'associationKey',
  ])

/**
 * Generates breadcrumb routes from a menu structure.
 *
 * @param menuStructure - Menu structure to generate routes from.
 * @param t - Optional translation function.
 * @returns Array of breadcrumb routes.
 */
export const routesFromMenu = (
  menuStructure: MenuStructure,
  t?: TFunction<'translate', undefined>,
): any => {
  return menuStructure.flatMap((menu) => {
    if ([MENU_ITEM_TYPES.ITEM, MENU_ITEM_TYPES.MENU].includes(menu.type)) {
      const { title, path } = menu as MenuItem
      return [
        {
          path,
          breadcrumb: t?.(title) || title,
        },
      ]
    }
    if (menu.type === MENU_ITEM_TYPES.GROUP) {
      const group = menu as MenuGroup
      return routesFromMenu(group.menuItems, t)
    }
    if (menu.type === MENU_ITEM_TYPES.SECTION) {
      const section = menu as MenuSection
      return routesFromMenu(section.menuContent, t)
    }
    return []
  })
}

/**
 * Reduces the length of a text string while preserving the beginning and end.
 *
 * @param text - Input text string.
 * @param maxCharacters - Maximum number of characters allowed in the shortened text.
 * @param extensionLength - Length of the extension added to the shortened text.
 * @returns Shortened text string.
 */
export function reduceTextLength(text: string, maxCharacters: number, extensionLength = 3) {
  if (text.length <= maxCharacters) {
    return text
  }
  const shortenedName = `${text.slice(0, maxCharacters - extensionLength + 3)}...${text.slice(
    -extensionLength,
  )}`
  return shortenedName
}

/**
 * Checks if a file meets specified criteria.
 *
 * @param types - Allowed file types.
 * @param maxFileSize - Maximum file size allowed in MB.
 * @returns A function to check the file.
 */
export const checkFile = (types: string[], maxFileSize: number) => (file: RcFile) => {
  if (!types.includes(file.type) || file.size / 1024 / 1024 >= maxFileSize) {
    if (!types.includes(file.type)) message.error(i18next.t('warning.file_not_supported'))
    else if (file.size / 1024 / 1024 >= maxFileSize)
      message.error(i18next.t('warning.file_too_large'))
    return Upload.LIST_IGNORE
  }
  return false
}

/**
 * Creates sanitation file data object.
 *
 * @param worksite_sanitation - Worksites sanitation ID.
 * @returns A function to create sanitation file data.
 */
export const createSanitationFileData =
  (worksite_sanitation: number | string) =>
  ({ originFileObj, tag, name }: UploadFileWithTag) => ({
    file: originFileObj,
    tag: tag as FILE_CATEGORY,
    filename: name,
    worksite_sanitation,
  })

/**
 * Retrieves sanitation equipment types.
 *
 * @param sanitation - Sanitation object.
 * @returns Record of sanitation equipment types.
 */
export function getSanitationEquipmentTypes(sanitation: Sanitation) {
  const record: Record<
    number,
    {
      enabledParameters: SanitationParameter[]
      associationKey: number
      availableParameters: SanitationParameter[]
    }
  > = {}
  for (const {
    equipment_type,
    enabled_parameters,
    id: associationKey,
  } of sanitation.equipment_type_settings) {
    record[equipment_type.id] = {
      enabledParameters: orderBy(enabled_parameters, (param) => param.order, 'asc'),
      associationKey,
      availableParameters: orderBy(
        equipment_type.available_parameters,
        (param) => param.order,
        'asc',
      ),
    }
  }
  return record
}

/**
 * Generates column configuration for monitoring parameters based on position and parameters.
 *
 * @param position - Measure position.
 * @param parameters - List of sanitation parameters.
 * @param options - Additional options for sorting.
 * @returns Array of column configuration objects.
 */
export const positionParametersCols = (
  position: MEASURE_POSITION,
  parameters: SanitationParameter[],
  options?: {
    paramsToSort?: number[]
  },
) =>
  parameters.map(({ name, unit, id, short_name }) => {
    const isParamToSort = options?.paramsToSort?.includes(id)
    return {
      title: short_name,
      className: 'text-xs !text-center',
      ...(isParamToSort
        ? {
            filterMultiple: false,
            onFilter: () => true,
            filters: enumToChoices(MEASURE_SORTS_TYPES, 'sort_type').map(({ value, label }) => ({
              text: i18next.t(label),
              value: `${value},${id}`,
            })),
          }
        : {}),
      onHeaderCell: () => ({ sorte: isParamToSort }),
      width: EDITABLE_CELL_WIDTH,
      children: [
        {
          title: unit,
          dataIndex: [position, id, 'value'],
          key: name,
          onHeaderCell: () => ({ className: 'text-xs text-center text-brand/70' }),
          className: 'text-xs !text-center !text-brand/70',
          onCell: (record: MonitoringMeasureRecordRow) => ({
            record,
            dataIndex: [position, id, 'value'],
            contentEditable: true,
          }),
        },
      ],
    }
  })

/**
 * Builds a dictionary of equipment measures.
 *
 * @param param - Parameters for building the dictionary.
 * @returns Array of equipment measures dictionary.
 */
export function buildEquipmentMeasureDictionary({
  data,
  sanitationId,
  monitoringId,
  sanitation,
  equipmentType,
}: {
  data: SanitationEquipmentMeasure[]
  sanitationId: number | string
  monitoringId: number | string
  sanitation: Sanitation
  equipmentType: number
}) {
  const enabledParameters = getSanitationEquipmentTypes(sanitation)[equipmentType].enabledParameters
  const dictionary: EquipmentMeasuresRecord[] | undefined = []

  for (const { id, equipment, group, treatment_unit, measures, air_samples } of data) {
    const equipmentData: EquipmentMeasuresRecord = {
      [MEASURE_POSITION.ENTRY]: {},
      [MEASURE_POSITION.EXIT]: {},
      [MEASURE_POSITION.NO_POSITION]: {},
      //@ts-ignore
      equipment: {
        ...equipment,
        id,
      },
      group,
      treatment_unit,
      sanitationId,
      monitoringId,
      masked: false,
    }
    for (const param of enabledParameters) {
      const measure = measures.find(({ position, parameter: { id } }) => {
        return position === MEASURE_POSITION.NO_POSITION && id == param.id
      }) || {
        id: null,
        last_value: null,
        value: null,
        value_long: null,
        position: MEASURE_POSITION.NO_POSITION,
        parameter: param,
      }
      const target = equipmentData[MEASURE_POSITION.NO_POSITION]
      target[param.id] = measure as any
    }
    for (const airSample of air_samples) {
      const target = equipmentData[airSample.position]
      target.airSamples = target.airSamples ? [...target.airSamples, airSample] : [airSample]
    }
    dictionary.push(equipmentData)
  }
  return dictionary.sort((a, b) =>
    customAlphabeticSorter(a.equipment.identifier, b.equipment.identifier),
  )
}

/**
 * Builds a download link for a file.
 *
 * @param file_path - Path of the file.
 * @returns Download link for the file.
 */
export function buildFileDownloadLink(file_path: string) {
  return appConfig.API_BASE_URL().slice(0, -appConfig.API_ENDPOINTS_PREFIX.length) + file_path
}

/**
 * Determines the monitoring measure section based on the equipment type.
 *
 * @param equipmentType - Type of equipment.
 * @returns Monitoring measure section.
 */
export function EquipmentTypeMonitoringMeasureSection(equipmentType: EQUIPMENTS_CATEGORIES) {
  switch (equipmentType) {
    case EQUIPMENTS_CATEGORIES.AIR_FILTER:
    case EQUIPMENTS_CATEGORIES.WATER_FILTER:
      return MONITORING_MEASURE_SECTIONS.FILTERS_SECTION
    case EQUIPMENTS_CATEGORIES.MEASURE_WELL:
      return MONITORING_MEASURE_SECTIONS.WELLS_SECTION
    case EQUIPMENTS_CATEGORIES.COLLECTOR:
    case EQUIPMENTS_CATEGORIES.EXTRACTOR:
      return MONITORING_MEASURE_SECTIONS.TREATMENT_UNITS_SECTION
    default:
      return MONITORING_MEASURE_SECTIONS.GLOBAL_MONITORING_SECTION
  }
}

/**
 * Extracts numbers from a string.
 *
 * @param element - The string from which to extract the number.
 * @returns The extracted number, or Infinity if no number is found.
 */
export const extractNumberFormString = (element: string) => {
  const match = element.match(/\d+/)
  return match ? parseInt(match[0]) : Infinity
}

/**
 * Custom sorter for alphabetic strings with embedded numbers.
 *
 * @param a - First string.
 * @param b - Second string.
 * @returns Comparison result.
 */
export const customAlphabeticSorter = (a: string, b: string) => {
  const numA = extractNumberFormString(a)
  const numB = extractNumberFormString(b)

  if (numA !== Infinity && numB !== Infinity) {
    return numA - numB
  }

  if (numA === Infinity && numB !== Infinity) {
    return 1
  }
  if (numA !== Infinity && numB === Infinity) {
    return -1
  }

  return a.localeCompare(b)
}

/**
 * Custom sorter for alphanumeric strings.
 *
 * @param a - First string.
 * @param b - Second string.
 * @returns Comparison result.
 */
export const customAlphanumericSorter = (a: string, b: string) => {
  const extractNumberFromString = (str: string) => {
    const num = str.match(/\d+/)
    return num ? parseInt(num[0]) : Infinity
  }

  const numA = extractNumberFromString(a)
  const numB = extractNumberFromString(b)

  if (numA !== Infinity && numB !== Infinity) {
    if (numA !== numB) {
      return numA - numB
    } else {
      return a.localeCompare(b)
    }
  }

  if (numA === Infinity && numB !== Infinity) {
    return -1
  }
  if (numA !== Infinity && numB === Infinity) {
    return 1
  }

  return a.localeCompare(b)
}

/**
 * Sorter function for sorting in descending order based on last value.
 *
 * @param A - First object to compare.
 * @param B - Second object to compare.
 * @param paramId - Parameter ID.
 * @returns Comparison result.
 */
export const descSorter = ({ no_position: A }: any, { no_position: B }: any, paramId: number) => {
  const valueA = A[paramId]?.last_value?.replace?.(',', '.') || Number.NEGATIVE_INFINITY
  const valueB = B[paramId]?.last_value?.replace?.(',', '.') || Number.NEGATIVE_INFINITY
  return valueB - valueA
}

/**
 * Sorter function for sorting in ascending order based on last value.
 *
 * @param A - First object to compare.
 * @param B - Second object to compare.
 * @param paramId - Parameter ID.
 * @returns Comparison result.
 */
export const ascSorter = ({ no_position: A }: any, { no_position: B }: any, paramId: number) => {
  const valueA = A[paramId]?.last_value?.replace?.(',', '.') || Number.POSITIVE_INFINITY
  const valueB = B[paramId]?.last_value?.replace?.(',', '.') || Number.POSITIVE_INFINITY
  return valueA - valueB
}

/**
 * Sorts the state based on the last value with treatment unit.
 *
 * @param state - State to be sorted.
 * @param params - Parameters for sorting.
 * @returns Sorted state.
 */
export const sortByLastValueWithTreatmentUnit = (
  state: Record<number, EquipmentMeasuresRecord[]>,
  params: PayloadAction<MeasuresSorterParams>,
) => {
  const { type, paramId, treatmentUnit } = params.payload
  if (treatmentUnit) {
    switch (type) {
      case MEASURE_SORTS_TYPES.LAST_VALUE_ASC:
        state[treatmentUnit] = state[treatmentUnit].sort((A, B) => {
          return ascSorter(A, B, paramId)
        })
        break
      case MEASURE_SORTS_TYPES.LAST_VALUE_DESC:
        state[treatmentUnit] = state[treatmentUnit].sort((A, B) => {
          return descSorter(A, B, paramId)
        })
        break
      case MEASURE_SORTS_TYPES.DEFAULT:
        state[treatmentUnit] = state[treatmentUnit].sort((a, b) =>
          customAlphanumericSorter(a.equipment.identifier, b.equipment.identifier),
        )
    }
  }
  return state
}

/**
 * Sorts measures by last value.
 *
 * @param state - State to be sorted.
 * @param param1 - Parameters for sorting.
 * @returns Sorted state.
 */
export const sortMeasuresByLastValue = (
  state: EquipmentMeasuresRecord[],
  { payload: { type, paramId } }: PayloadAction<MeasuresSorterParams>,
) => {
  switch (type) {
    case 'last_value_desc':
      return state.sort((a, b) => descSorter(a, b, paramId))
    case 'last_value_asc':
      return state.sort((a, b) => ascSorter(a, b, paramId))
    default:
      return state.sort((A, B) =>
        customAlphanumericSorter(A.equipment.identifier, B.equipment.identifier),
      )
  }
}

/**
 * Updates measure value with treatment unit.
 *
 * @param state - State to be updated.
 * @param param1 - Payload with update parameters.
 * @returns Updated state.
 */
export const updateMeasureValueWithTreatmentUnit = (
  state: Record<number, EquipmentMeasuresRecord[]>,
  {
    payload: { paramId, position, equipmentId, value, id, treatmentUnit },
  }: PayloadAction<{
    paramId: number
    position: MEASURE_POSITION
    equipmentId: number
    value: string
    id: number
    treatmentUnit?: number
  }>,
) => {
  state[treatmentUnit!] = state[treatmentUnit!].map((item) => {
    if (item.equipment.id === equipmentId) {
      const updatedParams = {
        ...item[position],
        [paramId]: { ...item[position][paramId], value, id },
      }
      return {
        ...item,
        [position]: updatedParams,
      }
    }
    return item
  })
  return state
}

/**
 * Updates air sample with treatment unit.
 *
 * @param state - State to be updated.
 * @param param1 - Payload with update parameters.
 * @returns Updated state.
 */
export const updateAirSampleWithTreatmentUnit = (
  state: Record<number, EquipmentMeasuresRecord[]>,
  {
    payload: { position, equipmentId, airSamples, treatmentUnit },
  }: PayloadAction<{
    position: MEASURE_POSITION
    equipmentId: number
    airSamples: AirSample[]
    treatmentUnit?: number
  }>,
) => {
  state[treatmentUnit!] = state[treatmentUnit!].map((item) => {
    if (item.equipment.id === equipmentId) {
      const updatedParams = {
        ...item[position],
        airSamples,
      }
      return {
        ...item,
        [position]: updatedParams,
      }
    }
    return item
  })
  return state
}

/**
 * Updates measure value.
 *
 * @param state - State to be updated.
 * @param param1 - Payload with update parameters.
 * @returns Updated state.
 */
export const updateMeasureValue = (
  state: EquipmentMeasuresRecord[],
  {
    payload: { paramId, position, equipmentId, value, id },
  }: PayloadAction<{
    paramId: number
    position: MEASURE_POSITION
    equipmentId: number
    value: string
    id: number
    treatmentUnit: number
  }>,
) => {
  return state.map((item) => {
    if (item.equipment.id === equipmentId) {
      const updatedParams = {
        ...item[position],
        [paramId]: { ...item[position][paramId], value, id },
      }
      return {
        ...item,
        [position]: updatedParams,
      }
    }
    return item
  })
}

/**
 * Updates air sample.
 *
 * @param state - State to be updated.
 * @param param1 - Payload with update parameters.
 * @returns Updated state.
 */
export const updateAirSample = (
  state: EquipmentMeasuresRecord[],
  {
    payload: { position, equipmentId, airSamples },
  }: PayloadAction<{
    position: MEASURE_POSITION
    equipmentId: number
    airSamples: AirSample[]
    treatmentUnit?: number
  }>,
) => {
  return state.map((item) => {
    if (item.equipment.id === equipmentId) {
      const updatedParams = {
        ...item[position],
        airSamples,
      }
      return {
        ...item,
        [position]: updatedParams,
      }
    }
    return item
  })
}

/**
 * Precises parameter value based on parameter ID and value.
 *
 * @param paramId - ID of the parameter.
 * @param value - Value to be precised.
 * @returns Precised value.
 */
export const preciseParamValue = (paramId: number, value: string = '') => {
  if (!PARAMS_PRECISION_BY_ID[paramId] || !paramId || !value) return value
  const precisedValue = parseFloat(value?.replace(',', '.'))
    .toFixed(PARAMS_PRECISION_BY_ID[paramId])
    .replace('.', ',')
  return precisedValue
}
