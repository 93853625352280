import { USER_ROLES } from '@/constants'
import { defaultLocale } from '@/locales'
import { getUserFullName } from '@/utils'
import { persistStore } from '@store/persistStore'

/**
 * Persists token in local storage.
 *
 * @param tokenType - Type of the token.
 * @param token - Token value.
 */
export const persistToken = (tokenType: TokenType, token: string): void => {
  persistStore.setItem(`${tokenType}Token`, token)
}

/**
 * Persists equipment types in local storage.
 *
 * @param data - Equipment types data to be persisted.
 */
export const persistEquipmentTypes = (data: EquipmentTypesRecord): void => {
  persistStore.setItem(`equipmentTypes`, data)
}

/**
 * Reads measurement units from local storage.
 *
 * @returns Measurement units data.
 */
export const readMeasurementUnits = (): SanitationParameter[] => {
  const value = persistStore.getItem(`measurementUnits`)
  return value ? value : undefined
}

/**
 * Persists measurement units in local storage.
 *
 * @param data - Measurement units data to be persisted.
 */
export const persistMeasurementUnits = (data: SanitationParameter[]): void => {
  persistStore.setItem(`measurementUnits`, data)
}

/**
 * Reads equipment types from local storage.
 *
 * @returns Equipment types data.
 */
export const readEquipmentTypes = (): EquipmentTypesRecord => {
  const value = persistStore.getItem(`equipmentTypes`)
  return value ? value : undefined
}

/**
 * Reads token from local storage.
 *
 * @param tokenType - Type of the token.
 * @returns Token value.
 */
export const readToken = (tokenType: TokenType): string | null => {
  return persistStore.getItem(`${tokenType}Token`) || null
}

/**
 * Retrieves preferred language from local storage.
 *
 * @returns Preferred language.
 */
export const prefLanguageFromStore = () => {
  return persistStore.getItem('lang') || defaultLocale
}

/**
 * Persists user permissions in local storage.
 *
 * @param permissions - User permissions to be persisted.
 */
export const persistUserPermissions = (permissions: USER_ROLES[]) => {
  return persistStore.setItem('permissions', permissions)
}

/**
 * Retrieves user permissions from local storage.
 *
 * @returns User permissions.
 */
export const getUserPermissions = (): USER_ROLES[] => {
  const permissions = persistStore.getItem('permissions')
  if (permissions && Array.isArray(permissions)) {
    return permissions
  }
  return []
}

/**
 * Persists user data in local storage.
 *
 * @param user - User data to be persisted.
 */
export const persistUser = (user: unknown): void => {
  persistStore.setItem('user', user)
}

/**
 * Reads user data from local storage.
 *
 * @returns User data.
 */
export const readUser = (): { userName: string; user: User } => {
  const userStr = persistStore.getItem('user')
  return userStr ? userStr : {}
}

/**
 * Sets session data in local storage.
 *
 * @param user - Logged-in user data.
 * @param access - Access token.
 * @param refresh - Refresh token.
 */
export const setSession = ({ user, access, refresh }: LogedUser): void => {
  persistUser({ userName: getUserFullName(user), user })
  persistToken('access', access)
  persistToken('refresh', refresh)
  persistUserPermissions(user.groups.map((group) => group.name))
}

/**
 * Unsets session data from local storage.
 */
export const unsetSession = (): void =>
  ['accessToken', 'refreshToken', 'user', 'permissions'].forEach((item) =>
    persistStore.removeItem(item),
  )
