import UserDropdown from './UserDropdown'
import MENUS, { HIDDEN_MENUS } from '@/routes/menus'
import AppBreadcrumb from './UI/AppBreadcrumb'
import { useLocation, useNavigate } from 'react-router'
import Icons from './UI/Icons'

export function SiteHeader() {
  const { pathname, state } = useLocation()
  const navigate = useNavigate()
  return (
    <div className='sticky top-1.5 z-40 flex h-20 rounded-2xl bg-white shadow-sm'>
      <div className='ml-[4.5rem] flex items-center gap-x-4 xl:ml-4'>
        {pathname.includes('dashboard') ? (
          <header className='flex items-center justify-between bg-white py-2'>
            <div
              onClick={() => navigate(-1)}
              className='flex size-11 items-center justify-center rounded-3xl bg-white  ring-4 ring-slate-200'
              role='button'
              tabIndex={0}
            >
              <Icons.ArrowLeftIcon className='size-9 p-2 text-brand' />
            </div>
            <div className='ml-4'>
              <span className='font-thin'>Tableau de bord</span>
              <p className='text-xl capitalize text-slate-950'>{state?.worksiteSanitation}</p>
            </div>
          </header>
        ) : (
          <AppBreadcrumb menuData={MENUS.concat(HIDDEN_MENUS)} />
        )}
      </div>
      <div className='mr-2 flex h-full flex-1 items-center justify-end'>
        <nav className='flex h-14 items-center justify-between gap-x-4 rounded-xl px-2'>
          <UserDropdown />
        </nav>
      </div>
    </div>
  )
}
