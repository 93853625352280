import en_US from 'antd/locale/en_US'
import fr_FR from 'antd/locale/fr_FR'

export const languages = {
  fr: {
    name: 'Français',
    flag: '🇫🇷',
    unicode: '1f1eb-1f1f7',
    antd: fr_FR,
  },
  en: {
    name: 'English',
    flag: '🇺🇸',
    unicode: '1f1fa-1f1f8',
    antd: en_US,
  },
}

export const defaultLocale: LanguageKey = 'fr'
