import { HTTP } from '../../services/httpServices'
import { EQUIPMENTS_TYPES_ENDPOINTS } from '../../constants/endpoints'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { persistEquipmentTypes, readEquipmentTypes } from '@/services/storageServices'
import orderBy from 'lodash/orderBy'

interface IPrefetchDataState {
  EquipmentTypes: EquipmentTypesRecord
}

/**
 * Initial state for the prefetch data slice.
 */
const initialState: IPrefetchDataState = {
  EquipmentTypes: readEquipmentTypes(),
}

/**
 * Thunk action creator to fetch equipment types asynchronously.
 */
export const fetchEquipmentTypes = createAsyncThunk(
  '/prefetch/EquipmentTypes',
  async (_payload, { rejectWithValue }) => {
    try {
      const res: AxiosResponse<PaginateList<EquipmentType>> = await HTTP.get(
        EQUIPMENTS_TYPES_ENDPOINTS.LIST,
      )
      return orderBy(res.data.results, (type) => type.order, 'asc')
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing prefetch data state and actions.
 */
export const prefetchDataSlice = createSlice({
  name: 'prefetch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchEquipmentTypes.fulfilled,
      (state, action: PayloadAction<EquipmentType[]>) => {
        const record: EquipmentTypesRecord = {} as any
        for (const type of action.payload) {
          record[type.name] = type
        }
        persistEquipmentTypes(record)
        state.EquipmentTypes = record
      },
    )
  },
})

export default prefetchDataSlice.reducer
