import Icons from '@/components/UI/Icons'
import { PATHS } from '@/routes/paths'
import { checkAuth } from '@/utils/middlewares'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router'
import FormLogin from './FormLogin'
import FormRecoverAccount from './FormRecoverAccount'
import FormRecoverAccountConfirm from './FormRecoverAccountConfirm'

export default function PageLogin() {
  const navigate = useNavigate()
  const isAuthenticated = checkAuth()
  useEffect(() => {
    if (isAuthenticated) {
      return navigate(PATHS.SANITATIONS.IN_PROGRESS_LIST.LINK)
    }
  })

  return (
    !isAuthenticated && (
      <main className='flex h-screen w-screen items-center justify-center overflow-hidden bg-white'>
        <div className='relative flex h-full flex-col items-center justify-center gap-y-10 px-4 lg:w-2/4'>
          <div className=''>
            <Icons.ShortLogo className='h-20' />
          </div>
          <Routes>
            <Route path={PATHS.AUTH.LOGIN_ROUTE} element={<FormLogin />} />
            <Route path={PATHS.AUTH.RECOVER_ACCOUNT_ROUTE} element={<FormRecoverAccount />} />
            <Route
              path={PATHS.AUTH.CONFIRM_ACCOUNT_RECOVER_ROUTE}
              element={<FormRecoverAccountConfirm />}
            />
          </Routes>
        </div>
      </main>
    )
  )
}
