import { EncryptStorage } from 'encrypt-storage'
import { appConfig } from '@/configs/appConfig'

/**
 * Encrypted storage instance for persisting data.
 */
export const persistStore = new EncryptStorage(`${appConfig.STORAGE_SECRET}`, {
  storageType: appConfig.STORAGE_TYPE,
  doNotEncryptValues: !appConfig.USE_ENCRYPT_STORE,
})
