import { checkAuthorization } from '../../utils/middlewares'
import { MENU_ITEM_TYPES } from '@/constants'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Icons from './Icons'

function ParseMenuItems(menuItems: MenuStructure): any {
  const { t } = useTranslation()
  return menuItems.map((item) => {
    if (checkAuthorization(item.accessPermissions)) {
      switch (item.type) {
        case MENU_ITEM_TYPES.SECTION:
          return {
            label: t(item.title),
            key: item.title,
            children: ParseMenuItems(item.menuContent),
          }
          break
        case MENU_ITEM_TYPES.GROUP:
          return {
            label: t(item.title),
            key: item.title,
            children: ParseMenuItems(item.menuItems),
            icon: <item.icon className='h-8' />,
          }
        case MENU_ITEM_TYPES.ITEM:
          return {
            label: <NavLink to={item.path}> {t(item.title)} </NavLink>,
            key: item.path,
            icon: <Icons.TagIcon className='h-2 scale-50' />,
          }
          break
        case MENU_ITEM_TYPES.MENU:
          return {
            label: <NavLink to={item.path}> {t(item.title)} </NavLink>,
            key: item.path,
            icon: <item.icon className='h-8' />,
          }
      }
    }
  })
}

export default ParseMenuItems
