import Button, { ButtonProps } from 'antd/es/button'
type ButtonIconProps = {
  onClick?: () => void
  colorType?: 'primary' | 'danger' | 'warning'
  Icon: Icon
} & Omit<ButtonProps, 'type' | 'icon'>

const colorTypeClassNames = {
  primary: 'bg-brand/10 text-brand hover:!bg-brand/20',
  danger: 'bg-red-500/10 text-red-500 hover:!bg-red-500/20 hover:!text-red-500',
  warning: 'bg-red-500/10 text-orange-500 hover:!bg-orange-500/20 hover:!text-orange-500',
}

export default function ButtonIcon({
  Icon,
  onClick,
  colorType = 'primary',
  className,
  loading,
  ...otherProps
}: ButtonIconProps) {
  return (
    <Button
      className={`size-11 rounded-2xl border-none ${colorTypeClassNames[colorType]} ${
        className ?? ''
      }`}
      onClick={onClick}
      {...otherProps}
    >
      <Icon className={`${loading && 'animate-spin'}`} />
    </Button>
  )
}
