// Authentication Endpoints
export const AUTH_ENDPOINTS = {
  LOGIN: 'auth/jwt/create/',
  LOGOUT: 'auth/jwt/logout/',
  REFRESH_TOKEN: 'auth/jwt/refresh/',
  RESET_PASSWORD: 'auth/set_password/',
  RECOVER_ACCOUNT: 'auth/reset_password/',
  CONFIRM_RECOVER_ACCOUNT: 'auth/reset_password_confirm/',
}

// Users Endpoints
export const USERS_ENDPOINTS = {
  LIST: 'users/',
  DETAILS: (id: number) => `users/${id}/`,
}

// Users Endpoints
export const DASHBOARD_ENDPOINTS = {
  LIST: (id: number) => `worksites_sanitation/${id}/get_power_bi_dashboard/`,
  DETAILS: (id: number) => `users/${id}/`,
}

// Files Endpoints
export const FILE_UPLOAD_ENDPOINTS = {
  LIST: 'uploads/',
  DETAILS: (id: number) => `uploads/${id}/`,
}

// Worksites Endpoints
export const WORKSITES_ENDPOINTS = {
  LIST: 'worksites/',
  DETAILS: (worksiteId: number) => `worksites/${worksiteId}/`,
}

// Sanitations Endpoints
export const SANITATIONS_ENDPOINTS = {
  LIST: 'worksites_sanitation/',
  DETAILS: (sanitationId: number) => `worksites_sanitation/${sanitationId}/`,
}

// Sanitations parameters Endpoints
export const SANITATION_PARAMETERS_ENDPOINTS = {
  LIST: 'sanitation_parameters/',
  DETAILS: (parameterId: number) => `sanitation_parameters/${parameterId}/`,
}

// Sanitations parameters Endpoints
export const EQUIPMENTS_TYPES_ENDPOINTS = {
  LIST: 'equipment-types/',
  DETAILS: (typeId: number) => `equipment-types/${typeId}/`,
}

// Sanitation treatement units

export const SANITATION_TREATMENT_UNITS_ENDPOINTS = {
  LIST: (sanitationId: number) => `worksites_sanitation/${sanitationId}/treatment_units/`,
  DETAILS: (sanitationId: number, treatementUnitId: number) =>
    `worksites_sanitation/${sanitationId}/treatment_units/${treatementUnitId}/`,
}

// Sanitation equipments groups
export const SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS = {
  LIST: (sanitationId: number) => `worksites_sanitation/${sanitationId}/equipment_groups/`,
  DETAILS: (sanitationId: number, groupId: number) =>
    `worksites_sanitation/${sanitationId}/equipment_groups/${groupId}/`,
}

// Sanitation monitoring
export const SANITATION_MONITORING = {
  LIST: (sanitationId: number) => `worksites_sanitation/${sanitationId}/monitoring/`,
  LOG: (sanitationId: number) =>
    `worksites_sanitation/${sanitationId}/monitoring_with_air_samples/`,
  DETAILS: (sanitationId: number | string, groupId: number | string) =>
    `worksites_sanitation/${sanitationId}/monitoring/${groupId}/`,
}

// Sanitation monitoring measures
export const SANITATION_MONITORING_MEASURE = {
  LIST: (sanitationId: string | number, monitoringId: string | number) =>
    `worksites_sanitation/${sanitationId}/monitoring/${monitoringId}/measures/`,
  DETAILS: (sanitationId: string | number, monitoringId: string | number, id: string | number) =>
    `worksites_sanitation/${sanitationId}/monitoring/${monitoringId}/measures/${id}/`,
}

// Sanitation equipment monitoring measures
export const SANITATION_EQUIPMENT_MEASURES = {
  LIST: (sanitationId: string | number, monitoringId: string | number) =>
    `worksites_sanitation/${sanitationId}/monitoring/${monitoringId}/measures_per_equipment/`,
  DETAILS: (sanitationId: string | number, monitoringId: string | number, id: string | number) =>
    `worksites_sanitation/${sanitationId}/monitoring/${monitoringId}/measures_per_equipment/${id}/`,
}

// Air samples endpoints
export const AIR_SAMPLE_ENDPOINTS = {
  LIST: (sanitationId: string | number) => `worksites_sanitation/${sanitationId}/air_samples/`,
  DETAILS: (sanitationId: string | number, id: string | number) =>
    `worksites_sanitation/${sanitationId}/air_samples/${id}/`,
}

// Labo results  endpoints
export const LABO_RESULTS = {
  LIST: (sanitationId: string | number) =>
    `worksites_sanitation/${sanitationId}/laboratory_results/`,
  CREATE_VIA_EXCEL: (sanitationId: string | number) =>
    `worksites_sanitation/${sanitationId}/laboratory_results/create_from_excel_file/`,
  DETAILS: (sanitationId: string | number, id: string | number) =>
    `worksites_sanitation/${sanitationId}/laboratory_results/${id}/`,
}

// Labos endpoints
export const LABORATORIES = {
  LIST: `laboratories/`,
}
