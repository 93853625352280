import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSanitationTreatmentUnits } from '@/services/sanitationTreatmentUnitServices'
import { createTreatmentUnitMap, customAlphanumericSorter } from '@/utils'
import { INFINITE } from '@/constants'

const initialState: TreatmentUnitData[] = []

/**
 * Thunk action creator to fetch sanitation treatment units asynchronously.
 * @param sanitation The sanitation object.
 */
export const fetchSanitationTreatmentUnits = createAsyncThunk(
  '/fetch/treatmentUnits',
  async ({ sanitation }: { sanitation: Sanitation }, { rejectWithValue }) => {
    try {
      const unitsMap = createTreatmentUnitMap(sanitation.equipments)
      const { results } = await getSanitationTreatmentUnits(sanitation.id, {
        limit: INFINITE,
      } as any)
      const buildedData: TreatmentUnitData[] = results.map((unit) => {
        const existUnit = unitsMap.get(unit.id)
        if (existUnit)
          return {
            ...existUnit,
            worksite_sanitation: sanitation.id,
            extractorCollector: [...existUnit.extractorCollector.values()],
          }
        return {
          ...unit,
          worksite_sanitation: sanitation.id,
          extractorCollector: [],
          recoveryPumps: [],
        }
      })
      return buildedData.map((item) => ({
        ...item,
        extractorCollector: item.extractorCollector?.sort((a, b) =>
          customAlphanumericSorter(a.extractor.identifier, b.extractor.identifier),
        ),
        recoveryPumps: item.recoveryPumps?.sort((a, b) =>
          customAlphanumericSorter(a.identifier, b.identifier),
        ),
      }))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing sanitation treatment units state and actions.
 */
export const sanitationTreatmentUnits = createSlice({
  name: 'treatmentUnits',
  initialState,
  reducers: {
    deleteExtractorCollector: (
      state,
      action: PayloadAction<{ unitId: number; groupId: number }>,
    ) => {
      const { unitId, groupId } = action.payload
      return state.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            extractorCollector: unit.extractorCollector?.filter(
              (couple) => couple.groupId !== groupId,
            ),
          }
        }
        return unit
      })
    },
    deleteRecoveryPump: (state, action: PayloadAction<{ unitId: number; pumpId: number }>) => {
      const { unitId, pumpId } = action.payload
      return state.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            recoveryPumps: unit.recoveryPumps?.filter((pump) => pump.id !== pumpId),
          }
        }
        return unit
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSanitationTreatmentUnits.fulfilled,
      (_, action: PayloadAction<TreatmentUnitData[]>) => {
        return action.payload
      },
    )
  },
})

export const { deleteExtractorCollector, deleteRecoveryPump } = sanitationTreatmentUnits.actions

export default sanitationTreatmentUnits.reducer
