import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createMeasureWellMap, customAlphanumericSorter } from '@/utils'
import { getSanitationEquipmentGroups } from '@/services/sanitationGroupServices'
import { INFINITE } from '@/constants'

const initialState: MeasureWellData[] = []

/**
 * Thunk action creator to fetch sanitation well groups asynchronously.
 * @param sanitation The sanitation object.
 */
export const fetchWellsGroup = createAsyncThunk(
  '/fetch/wellsGroup',
  async ({ sanitation }: { sanitation: Sanitation }, { rejectWithValue }) => {
    try {
      const wellsMap = createMeasureWellMap(sanitation.equipments)
      const { results } = await getSanitationEquipmentGroups(sanitation.id, {
        limit: INFINITE,
      } as any)
      const buildedData: MeasureWellData[] = [...wellsMap.values()].concat(
        results
          .filter((item) => !item.is_for_treatment_unit && !wellsMap.get(item.id))
          .map((group) => ({
            identifier: group.number,
            groupId: group.id,
            group: '',
            measureWells: [],
          })),
      )
      return buildedData.map((item) => ({
        ...item,
        measureWells: item.measureWells.sort((a, b) =>
          customAlphanumericSorter(a.identifier, b.identifier),
        ),
      }))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing sanitation measure well groups state and actions.
 */
export const sanitationMeasuresWells = createSlice({
  name: 'wellsGroups',
  initialState,
  reducers: {
    deleteWell: (state, action: PayloadAction<{ groupId: number; well: number }>) => {
      const { groupId, well } = action.payload
      return state.map((group) => {
        if (group.groupId == groupId)
          return { ...group, measureWells: group.measureWells.filter((w) => w.id !== well) }
        return group
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWellsGroup.fulfilled, (_, action: PayloadAction<MeasureWellData[]>) => {
      return action.payload
    })
  },
})

export const { deleteWell } = sanitationMeasuresWells.actions

export default sanitationMeasuresWells.reducer
