import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSanitationEquipmentMeasure } from '@/services/sanitationEquipmentMeasureServices'
import { EQUIPMENTS_CATEGORIES } from '@/constants'
import {
  buildEquipmentMeasureDictionary,
  sortByLastValueWithTreatmentUnit,
  updateAirSampleWithTreatmentUnit,
  updateMeasureValueWithTreatmentUnit,
} from '@/utils'
import { readEquipmentTypes } from '@/services/storageServices'

type FetchEquipmentMeasuresProps = {
  sanitationId: string | number
  monitoringId: string | number
  sanitation: Sanitation
}

const initialState: Record<number, EquipmentMeasuresRecord[]> = {}

/**
 * Thunk action creator to fetch collector measures asynchronously.
 * @param sanitationId The ID of the sanitation.
 * @param monitoringId The ID of the monitoring.
 * @param sanitation The sanitation object.
 */
export const fetchCollectorsMeasures = createAsyncThunk(
  '/prefetch/collectorsMeasure',
  async (
    { sanitationId, monitoringId, sanitation }: FetchEquipmentMeasuresProps,
    { rejectWithValue },
  ) => {
    try {
      const { results } = await getSanitationEquipmentMeasure(sanitationId, monitoringId, {
        equipment_type: EQUIPMENTS_CATEGORIES.COLLECTOR,
      })
      return {
        data: results,
        sanitationId,
        monitoringId,
        sanitation,
        equipmentType: readEquipmentTypes().collector.id,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing collector measures state and actions.
 */
export const collectorsMeasures = createSlice({
  name: 'collectorsMeasure',
  initialState,
  reducers: {
    sortCollectorsMeasuresByLastValue: sortByLastValueWithTreatmentUnit,
    updateCollectorsMeasuresParamValue: updateMeasureValueWithTreatmentUnit,
    updateCollectorsAirSample: updateAirSampleWithTreatmentUnit,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCollectorsMeasures.fulfilled,
      (
        _,
        action: PayloadAction<{
          data: SanitationEquipmentMeasure[]
          sanitationId: number | string
          monitoringId: number | string
          sanitation: Sanitation
          equipmentType: number
        }>,
      ) => {
        const state: Record<number, EquipmentMeasuresRecord[]> = {}
        for (const record of buildEquipmentMeasureDictionary(action.payload)) {
          if (state[record.treatment_unit.id]) {
            state[record.treatment_unit.id].push(record)
          } else {
            state[record.treatment_unit.id] = [record]
          }
        }
        return state
      },
    )
  },
})

export const {
  sortCollectorsMeasuresByLastValue,
  updateCollectorsMeasuresParamValue,
  updateCollectorsAirSample,
} = collectorsMeasures.actions

export default collectorsMeasures.reducer
