import { SANITATION_TREATMENT_UNITS_ENDPOINTS } from '@/constants/endpoints'
import { HTTP } from './httpServices'
import { AxiosResponse } from 'axios'

export const createSanitationTreatmentUnit = (
  sanitationId: number,
  payload: {
    worksite_sanitation: number
    identifier: string
  },
): Promise<AxiosResponse<TreatmentUnit>> =>
  HTTP.post(SANITATION_TREATMENT_UNITS_ENDPOINTS.LIST(sanitationId), payload)

export const getSanitationTreatmentUnits = (
  sanitationId: number,
  pagination?: Pagination,
): Promise<PaginateList<TreatmentUnit>> => {
  return HTTP.get(`${SANITATION_TREATMENT_UNITS_ENDPOINTS.LIST(sanitationId)}`, {
    params: pagination,
  }).then(({ data }) => data)
}

export const getSanitationTreatmentUnit = (
  sanitationId: number,
  treatementUnitId: number,
): Promise<TreatmentUnit> =>
  HTTP.get(SANITATION_TREATMENT_UNITS_ENDPOINTS.DETAILS(sanitationId, treatementUnitId)).then(
    ({ data }) => data,
  )

export const updateSanitationTreatmentUnit = (
  sanitationId: number,
  treatementUnitId: number,
  data: Omit<TreatmentUnitData, 'extractorCollector'>,
): Promise<AxiosResponse<TreatmentUnit>> =>
  HTTP.put(SANITATION_TREATMENT_UNITS_ENDPOINTS.DETAILS(sanitationId, treatementUnitId), data)

export const deleteSanitationTreatmentUnit = (
  sanitationId: number,
  treatementUnitId: number,
): Promise<TreatmentUnit> =>
  HTTP.delete(SANITATION_TREATMENT_UNITS_ENDPOINTS.DETAILS(sanitationId, treatementUnitId)).then(
    ({ data }) => data,
  )
