import { SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS } from '@/constants/endpoints'
import { HTTP } from './httpServices'
import { AxiosResponse } from 'axios'

export const createSanitationEquipmentGroup = (
  sanitationId: number,
  payload: EquipmentGroupData,
): Promise<AxiosResponse<EquipmentGroup>> =>
  HTTP.post(SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS.LIST(sanitationId), payload)

export const getSanitationEquipmentGroups = (
  sanitationId: number,
  pagination?: Pagination,
): Promise<PaginateList<EquipmentGroup>> => {
  return HTTP.get(`${SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS.LIST(sanitationId)}`, {
    params: pagination,
  }).then(({ data }) => data)
}

export const getSanitationEquipmentGroup = (
  sanitationId: number,
  groupId: number,
): Promise<EquipmentGroup> =>
  HTTP.get(SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS.DETAILS(sanitationId, groupId)).then(
    ({ data }) => data,
  )

export const updateSanitationEquipmentGroup = (
  sanitationId: number,
  groupId: number,
  data: EquipmentGroupData,
): Promise<AxiosResponse<EquipmentGroup>> =>
  HTTP.put(SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS.DETAILS(sanitationId, groupId), data)

export const deleteSanitationEquipmentGroup = (
  sanitationId: number,
  groupId: number,
): Promise<EquipmentGroup> =>
  HTTP.delete(SANITATION_EQUIPMENTS_GROUPS_ENDPOINTS.DETAILS(sanitationId, groupId)).then(
    ({ data }) => data,
  )
