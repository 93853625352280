import { Suspense, useCallback, useState } from 'react'
import Sidebar from '@/components/Sidebar'
import Pages from '@/routes/routes'
import { Navigate, Route, Routes } from 'react-router-dom'
import { checkAuth, checkAuthorization } from '@/utils/middlewares'
import { PATHS } from '@/routes/paths'
import { SiteHeader } from '@/components/SiteHeader'
import Spin from 'antd/es/spin'

export default function UserLayout() {
  const [Open, setOpen] = useState(false)
  const toggleSidebar = useCallback(() => setOpen((prev) => !prev), [setOpen])
  if (checkAuth())
    return (
      <main>
        <Sidebar open={Open} onClose={toggleSidebar} />
        <div className='w-full px-2 xl:ml-[290px] xl:w-[calc(100vw-304px)] xl:px-0'>
          <SiteHeader />
          <div className='mt-4 h-[calc(100vh-6rem)]'>
            <Suspense fallback={<Spin spinning size='small' className='relative top-1/2 w-full' />}>
              <Routes>
                {Pages.map((page) => (
                  <Route
                    path={page.path}
                    element={
                      checkAuthorization(page.access) ? (
                        <page.component />
                      ) : (
                        <Navigate
                          to={{
                            pathname: PATHS.REDIRECT.FORBIDDEN,
                          }}
                          state={{
                            prev: window.location.pathname,
                          }}
                        />
                      )
                    }
                    key={page.path}
                  />
                ))}
              </Routes>
            </Suspense>
          </div>
        </div>
      </main>
    )
  return (
    <Navigate
      to={{
        pathname: PATHS.AUTH.LOGIN_PATH,
      }}
    />
  )
}
