import Menu from 'antd/es/menu'
import Icons from './UI/Icons'
import MENUS from '@/routes/menus'
import ParseMenuItems from './UI/ParseMenuItems'
import { useLocation } from 'react-router'
import { MENU_ITEM_TYPES } from '@/constants'
import { useMemo } from 'react'

function Sidebar({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { pathname } = useLocation()

  const activePath = useMemo(
    () =>
      MENUS.find(
        (menu) => menu.type === MENU_ITEM_TYPES.MENU && pathname.includes(menu.path),
      ) as MenuItem,
    [pathname],
  )

  return (
    <div
      className={`fixed left-0 z-50 h-screen min-w-[280px] max-w-[280px] border-none bg-white shadow ring-2 ring-slate-100  transition-all duration-150 ${
        open ? 'translate-x-0' : '-translate-x-full xl:translate-x-0'
      } `}
    >
      <div className='relative flex h-[5.5rem] items-center justify-center border-b'>
        <Icons.Logo
          onClick={() => {
            document.querySelector('#root')?.requestFullscreen({
              navigationUI: 'hide',
            })
          }}
          className='mt-2.5 h-8 xl:h-12 xl:p-1'
        />
        <button
          onClick={onClose}
          className={`absolute top-6 flex size-11 items-center justify-center rounded-xl bg-white text-brand ring-4 ring-slate-200 xl:hidden ${
            open ? '-right-8' : 'right-[-4.4rem] '
          } `}
        >
          {open ? <Icons.XMarkIcon className='size-9 p-2' /> : <Icons.Bars3BottomLeftIcon className='size-9 p-2' />}
        </button>
      </div>
      <Menu
        selectedKeys={[activePath?.path]}
        inlineIndent={0}
        mode='inline'
        defaultOpenKeys={MENUS.filter((menu) => menu.type === MENU_ITEM_TYPES.GROUP).map(
          ({ title }) => title,
        )}
        items={ParseMenuItems(MENUS)}
        className='mt-3 overflow-hidden text-base'
      />
    </div>
  )
}

export default Sidebar
