import { MENU_ITEM_TYPES } from '@/constants'
import { PATHS } from './paths'
import Icons from '@/components/UI/Icons'

export const HIDDEN_MENUS: MenuItem[] = [
  /***************SETTINGS MENU***********************/
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SETTING,
    title: 'modules.settings.default.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.MONITORING.LINK(),
    title: 'modules.monitorings.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.MONITORING.LINK2(),
    title: 'modules.monitorings.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.MEASURES.LINK(),
    title: 'modules.measures.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.MEASURES.LINK2(),
    title: 'modules.measures.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.LABO_RESULTS.LINK(),
    title: 'modules.labo_results.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.LABO_RESULTS.LINK2(),
    title: 'modules.labo_results.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.CREATE.LINK,
    title: 'modules.sanitations.create.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.UPDATE.LINK(),
    title: 'modules.sanitations.update.title',
    accessPermissions: {
      permissions: [],
    },
  },
  {
    type: MENU_ITEM_TYPES.ITEM,
    path: PATHS.SANITATIONS.UPDATE.LINK2(),
    title: 'modules.sanitations.update.title',
    accessPermissions: {
      permissions: [],
    },
  },
]

const MENUS: MenuStructure = [
  {
    type: MENU_ITEM_TYPES.GROUP,
    icon: Icons.FlagIcon,
    title: 'modules.sanitations.title',
    accessPermissions: PATHS.SANITATIONS.ACCESS,
    defaultOpen: true,
    menuItems: [
      {
        type: MENU_ITEM_TYPES.ITEM,
        path: PATHS.SANITATIONS.IN_PROGRESS_LIST.LINK,
        title: 'modules.sanitations.in_progress.title',
        accessPermissions: PATHS.SANITATIONS.IN_PROGRESS_LIST.ACCESS,
      },
      {
        type: MENU_ITEM_TYPES.ITEM,
        path: PATHS.SANITATIONS.COMPLETED_LIST.LINK,
        title: 'modules.sanitations.completed.title',
        accessPermissions: PATHS.SANITATIONS.COMPLETED_LIST.ACCESS,
      },
    ],
  },
  {
    type: MENU_ITEM_TYPES.MENU,
    icon: Icons.UserGroupIcon,
    path: PATHS.USERS.LIST.LINK,
    title: 'modules.users.list.title',
    accessPermissions: PATHS.USERS.LIST.ACCESS,
  },
]

export default MENUS
