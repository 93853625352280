import Breadcrumb from 'antd/es/breadcrumb'
import { useNavigate } from 'react-router-dom'
import Icons from './Icons'
import { HIDDEN_MENUS } from '@/routes/menus'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { routesFromMenu } from '@/utils'

const AppBreadcrumb = ({ menuData }: { menuData: MenuStructure }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs(routesFromMenu(menuData, t), {
    disableDefaults: true,
  })
  const hideGoBackButton = useMemo(
    () => !HIDDEN_MENUS.map((menu) => menu.path).includes(location.pathname),
    [location.pathname],
  )
  return (
    <div className='ml-1 flex items-center gap-x-3 xl:ml-0'>
      <div
        hidden={hideGoBackButton}
        onClick={() => navigate(-1)}
        className='rounded-3xl bg-white p-1 ring-4 ring-slate-200'
        role='button'
        tabIndex={0}
      >
        <Icons.ArrowLeftIcon fontWeight={100} className='size-9 p-2 text-brand' />
      </div>

      <Breadcrumb
        separator={<Icons.ChevronRightIcon className='mt-1.5 h-3' />}
        items={breadcrumbs.map((item) => ({
          title: item.breadcrumb,
          className: 'cursor-pointer text-base',
          onClick: () => navigate(item.match.pathname, { state: item.location.state }),
        }))}
      />
    </div>
  )
}

export default AppBreadcrumb
