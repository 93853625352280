import { USER_ROLES } from '@/constants'
import { getUserPermissions, readToken } from '@services/storageServices'

/**
 * Object representing access type for routes.
 */
export type RoutesAccessType = {
  requireAll?: boolean
  permissions: USER_ROLES[]
}

/**
 * Check if user is authenticated.
 * @returns Boolean indicating if user is authenticated.
 */
export const checkAuth = (): boolean => {
  return readToken('access') ? true : false
}

/**
 * Check if user has the required permissions for accessing a route.
 * @param options The access type options.
 * @returns Boolean indicating if user has the required permissions.
 */
export const checkAuthorization = (options: RoutesAccessType) => {
  const userPermissions = getUserPermissions()
  const authorizationFunction = options.requireAll ? hasAllPermissions : hasOneOrMorePermissions
  return authorizationFunction(userPermissions, options.permissions)
}

/**
 * Check if user has at least one of the required permissions.
 * @param allPermissions All permissions of the user.
 * @param requiredPermissions Required permissions for accessing the route.
 * @returns Boolean indicating if user has at least one of the required permissions.
 */
export const hasOneOrMorePermissions = (
  allPermissions: USER_ROLES[],
  requiredPermissions: USER_ROLES[],
) => {
  return (
    requiredPermissions.length === 0 ||
    requiredPermissions.some((permission) => allPermissions.includes(permission))
  )
}

/**
 * Check if user has all of the required permissions.
 * @param allPermissions All permissions of the user.
 * @param requiredPermissions Required permissions for accessing the route.
 * @returns Boolean indicating if user has all of the required permissions.
 */
export const hasAllPermissions = (allPermissions: string[], requiredPermissions: string[]) => {
  return (
    requiredPermissions.length === 0 ||
    requiredPermissions.every((permission) => allPermissions.includes(permission))
  )
}
