import UserLayout from '@layouts/UserLayout'
import { Route, Routes } from 'react-router-dom'
import ConfigProvider from 'antd/es/config-provider'
import { formConfig, themeConfig } from './configs/themeConfig'
import { useAppSelector } from './store'
import { languages } from '@/locales'
import { PATHS } from './routes/paths'
import { Suspense } from 'react'
import Icons from './components/UI/Icons'
import LoginPage from './pages/auth/PageLogin'

export default function App() {
  const currentLocal = useAppSelector((state) => state.appLocal.current)
  return (
    <ConfigProvider locale={languages[currentLocal].antd} theme={themeConfig} form={formConfig}>
      <Suspense
        fallback={
          <div className='flex h-svh items-center justify-center'>
            <Icons.Logo className='mx-auto max-w-28 animate-pulse grayscale' />
          </div>
        }
      >
        <Routes>
          <Route path={PATHS.AUTH.LAYER} element={<LoginPage />} />
          <Route path='*' element={<UserLayout />} />
        </Routes>
      </Suspense>
    </ConfigProvider>
  )
}
