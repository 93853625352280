import ViewfinderCircleIcon from '@heroicons/react/24/outline/esm/ViewfinderCircleIcon'
import ListBulletIcon from '@heroicons/react/24/outline/esm/ListBulletIcon'
import PlusIcon from '@heroicons/react/24/outline/esm/PlusIcon'
import TagIcon from '@heroicons/react/24/outline/esm/TagIcon'
import ExclamationCircleIcon from '@heroicons/react/24/outline/esm/ExclamationCircleIcon'
import BeakerIcon from '@heroicons/react/24/outline/esm/BeakerIcon'
import CheckIcon from '@heroicons/react/24/outline/esm/CheckIcon'
import HashtagIcon from '@heroicons/react/24/outline/esm/HashtagIcon'
import CodeBracketSquareIcon from '@heroicons/react/24/outline/esm/CodeBracketSquareIcon'
import PencilIcon from '@heroicons/react/24/outline/esm/PencilIcon'
import Cog6ToothIcon from '@heroicons/react/24/outline/esm/Cog6ToothIcon'
import Bars3BottomLeftIcon from '@heroicons/react/24/outline/esm/Bars3BottomLeftIcon'
import XMarkIcon from '@heroicons/react/24/outline/esm/XMarkIcon'
import ChatBubbleBottomCenterIcon from '@heroicons/react/24/outline/esm/ChatBubbleBottomCenterIcon'
import ArrowPathIcon from '@heroicons/react/24/outline/esm/ArrowPathIcon'
import InformationCircleIcon from '@heroicons/react/24/outline/esm/InformationCircleIcon'
import DocumentTextIcon from '@heroicons/react/24/outline/esm/DocumentTextIcon'
import ArrowLeftIcon from '@heroicons/react/24/outline/esm/ArrowLeftIcon'
import BackspaceIcon from '@heroicons/react/24/outline/esm/BackspaceIcon'
import UserIcon from '@heroicons/react/24/outline/esm/UserIcon'
import PowerIcon from '@heroicons/react/24/outline/esm/PowerIcon'
import CogIcon from '@heroicons/react/24/outline/esm/CogIcon'
import PlusCircleIcon from '@heroicons/react/24/outline/esm/PlusCircleIcon'
import EyeIcon from '@heroicons/react/24/outline/esm/EyeIcon'
import ArrowRightIcon from '@heroicons/react/24/outline/esm/ArrowRightIcon'
import TrashIcon from '@heroicons/react/24/outline/esm/TrashIcon'
import QueueListIcon from '@heroicons/react/24/outline/esm/QueueListIcon'
import ChevronRightIcon from '@heroicons/react/24/outline/esm/ChevronRightIcon'
import AdjustmentsHorizontalIcon from '@heroicons/react/24/outline/esm/AdjustmentsHorizontalIcon'
import ChevronUpDownIcon from '@heroicons/react/24/outline/esm/ChevronUpDownIcon'
import LanguageIcon from '@heroicons/react/24/outline/esm/LanguageIcon'
import ArrowUpIcon from '@heroicons/react/24/outline/esm/ArrowUpIcon'
import ArrowDownIcon from '@heroicons/react/24/outline/esm/ArrowDownIcon'
import ArrowsUpDownIcon from '@heroicons/react/24/outline/esm/ArrowsUpDownIcon'
import CloudArrowDownIcon from '@heroicons/react/24/outline/esm/CloudArrowDownIcon'
import PhotoIcon from '@heroicons/react/24/outline/esm/PhotoIcon'
import CloudArrowUpIcon from '@heroicons/react/24/outline/esm/CloudArrowUpIcon'
import ChartPieIcon from '@heroicons/react/24/outline/esm/ChartPieIcon'
import TableCellsIcon from '@heroicons/react/24/outline/esm/TableCellsIcon'
import Squares2X2Icon from '@heroicons/react/24/outline/esm/Squares2X2Icon'
import ArrowsPointingOutIcon from '@heroicons/react/24/outline/esm/ArrowsPointingOutIcon'
import CheckCircleIcon from '@heroicons/react/24/outline/esm/CheckCircleIcon'
import ArrowTopRightOnSquareIcon from '@heroicons/react/24/outline/esm/ArrowTopRightOnSquareIcon'
import GlobeAltIcon from '@heroicons/react/24/outline/esm/GlobeAltIcon'
import ShieldCheckIcon from '@heroicons/react/24/outline/esm/ShieldCheckIcon'
import FlagIcon from '@heroicons/react/24/outline/esm/FlagIcon'
import UserGroupIcon from '@heroicons/react/24/outline/esm/UserGroupIcon'

const Icons = {
  UserGroupIcon,
  FlagIcon,
  ShieldCheckIcon,
  GlobeAltIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  ArrowsPointingOutIcon,
  Squares2X2Icon,
  TableCellsIcon,
  ChartPieIcon,
  PhotoIcon,
  CloudArrowUpIcon,
  CloudArrowDownIcon,
  ArrowsUpDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  LanguageIcon,
  ChevronUpDownIcon,
  AdjustmentsHorizontalIcon,
  ChevronRightIcon,
  QueueListIcon,
  TrashIcon,
  ExclamationCircleIcon,
  HashtagIcon,
  ArrowRightIcon,
  EyeIcon,
  PlusCircleIcon,
  CogIcon,
  UserIcon,
  PowerIcon,
  BackspaceIcon,
  ArrowLeftIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  CheckIcon,
  ViewfinderCircleIcon,
  PlusIcon,
  BeakerIcon,
  ArrowPathIcon,
  ChatBubbleBottomCenterIcon,
  Bars3BottomLeftIcon,
  PencilIcon,
  XMarkIcon,
  TagIcon,
  Cog6ToothIcon,
  ListBulletIcon,
  CodeBracketSquareIcon,
  Logo: (props: React.PropsWithRef<any>) => <img src='/logo.svg' {...props} />,
  ShortLogo: (props: React.PropsWithRef<any>) => <img src='/short_logo.svg' {...props} />,
  AirPlaneMail: (props: React.PropsWithRef<any>) => <img src='/airplane_mail.png' {...props} />,
  ExtraUserLeader: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <g>
        <path d='M21.08 10.873c0 1.873-.286 3.173-1.164 3.977a8.034 8.034 0 0 1 3.648 4.188c.124.328.153.708-.052.993-.568.79-1.673-.11-2.129-.97a6 6 0 0 0-10.604 0c-.456.86-1.561 1.76-2.13.97-.205-.285-.175-.665-.051-.993a8.025 8.025 0 0 1 3.649-4.188c-.88-.804-1.166-2.104-1.166-3.977a5 5 0 0 1 10 0zm-2.468 2.456c.198-.215.469-.773.469-2.456a3 3 0 1 0-6 0c0 1.683.272 2.24.47 2.456.163.176.68.544 2.53.544 1.85 0 2.367-.368 2.53-.544z' />
        <path d='M15.098 19.127a1 1 0 0 1 2 0v1.268l1.098-.634a1 1 0 0 1 1 1.732l-1.098.634 1.098.634a1 1 0 1 1-1 1.732l-1.098-.634v1.268a1 1 0 1 1-2 0v-1.268L14 24.493a1 1 0 1 1-1-1.732l1.098-.634L13 21.493a1 1 0 0 1 1-1.732l1.098.634v-1.268z' />
      </g>
    </svg>
  ),
  ReloadIcon: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <path d='M20.726,14.162c-0.776,-2.415 -3.04,-4.162 -5.713,-4.162c-3.314,0 -6,2.686 -6,6c0,3.314 2.686,6 6,6c2.063,0 3.883,-1.041 4.962,-2.626c0.333,-0.489 0.988,-0.707 1.497,-0.406c0.437,0.26 0.611,0.814 0.342,1.246c-1.411,2.273 -3.929,3.786 -6.801,3.786c-4.418,0 -8,-3.582 -8,-8c0,-4.418 3.582,-8 8,-8c3.53,0 6.525,2.286 7.588,5.458c0.278,-0.429 0.537,-0.848 0.736,-1.175c0.108,-0.178 0.196,-0.324 0.258,-0.428l0.042,-0.07l0.029,-0.05l0.018,-0.03l0.005,-0.008l0.001,-0.002c0.279,-0.476 0.892,-0.636 1.368,-0.357c0.477,0.279 0.636,0.892 0.357,1.369l-0.001,0.001l-0.001,0.002l-0.005,0.009l-0.02,0.034c-0.017,0.028 -0.043,0.072 -0.075,0.125l-0.062,0.105l-0.015,0.025l-0.084,0.139c-0.033,0.055 -0.069,0.114 -0.107,0.176c-0.222,0.365 -0.528,0.86 -0.849,1.35c-0.32,0.49 -0.676,1.006 -0.978,1.389c-0.157,0.198 -0.336,0.402 -0.482,0.54c-0.084,0.077 -0.217,0.181 -0.316,0.243c-0.103,0.062 -0.357,0.201 -0.695,0.178c-0.202,-0.013 -0.369,-0.08 -0.447,-0.113c-0.083,-0.035 -0.195,-0.091 -0.283,-0.141c-0.164,-0.092 -0.372,-0.224 -0.575,-0.363c-0.399,-0.272 -0.884,-0.633 -1.337,-0.98c-0.453,-0.348 -0.892,-0.697 -1.214,-0.954c-0.162,-0.13 -0.296,-0.239 -0.389,-0.314l-0.108,-0.088l-0.029,-0.024l-0.007,-0.006l-0.002,-0.001l-0.001,-0.001c-0.427,-0.35 -0.489,-0.98 -0.139,-1.407c0.35,-0.428 0.98,-0.489 1.407,-0.139l0.036,0.029l0.104,0.085c0.091,0.074 0.221,0.178 0.379,0.305c0.317,0.255 0.74,0.59 1.181,0.928c0.143,0.11 0.285,0.218 0.425,0.323Z' />
    </svg>
  ),
  ExtraAdminIcon: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <path d='M21.437 17.023a1 1 0 0 1 .785.977v2.2H23a1 1 0 0 1 .907 1.42l-2.222 4.8a1 1 0 0 1-1.907-.42v-2.2H19a1 1 0 0 1-.907-1.42l2.222-4.8a1 1 0 0 1 1.122-.557zM19.834 16.977C20.714 16.173 21 14.873 21 13a5 5 0 0 0-10 0c0 1.873.286 3.173 1.165 3.977a8.029 8.029 0 0 0-3.6 4.062c-.162.408-.213.939.162 1.168.792.485 1.632-.457 2.105-1.257a5.997 5.997 0 0 1 5.48-2.942c1.132.058 2.373-.055 3.24-.784a8.75 8.75 0 0 0 .282-.247zM19 13c0 1.683-.271 2.241-.47 2.456-.162.176-.68.544-2.53.544-1.85 0-2.368-.368-2.53-.544C13.27 15.24 13 14.683 13 13a3 3 0 1 1 6 0z' />
    </svg>
  ),
  ExtraExpandIcon: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <path d='M13.4261 23.4929C13.9761 23.4429 14.4625 23.8483 14.5125 24.3983C14.5625 24.9483 14.1572 25.4347 13.6072 25.4847L13.6055 25.4848C11.7489 25.903 10.3347 25.903 7.83297 25.6519C7.45861 25.5861 7.07499 25.4718 6.7991 25.1959C6.52322 24.92 6.40892 24.5364 6.34311 24.162C6.09202 21.6604 6.09202 20.2462 6.51027 18.3884C6.63158 17.8496 7.04673 17.4325 7.59675 17.4825C8.14676 17.5325 8.5521 18.0189 8.50211 18.5689C8.49565 18.6403 8.48931 18.7102 8.48309 18.779C8.34826 20.2675 8.26727 21.1616 8.26533 22.3155L22.3035 8.27736C21.1495 8.2793 20.2555 8.36029 18.7669 8.49512L18.5569 8.51413C18.0069 8.56413 17.5205 8.15879 17.4705 7.60878C17.4205 7.05876 17.8375 6.64361 18.3763 6.5223C20.2342 6.10404 21.6484 6.10404 24.15 6.35514C24.5244 6.42095 24.908 6.53525 25.1839 6.81113C25.4598 7.08702 25.5741 7.47064 25.6399 7.845C25.891 10.3467 25.891 11.7609 25.4728 13.6176L25.4727 13.6192C25.4227 14.1692 24.9363 14.5745 24.3862 14.5245C23.8362 14.4745 23.4309 13.9881 23.4809 13.4381L23.5092 13.1202C23.6342 11.7164 23.7135 10.8267 23.7167 9.69262L9.68059 23.7287C10.8147 23.7255 11.7044 23.6463 13.1082 23.5212C13.2113 23.512 13.3171 23.5026 13.4261 23.4929Z' />
    </svg>
  ),
  ExtraPersonIcon: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <path d='M19,13c0,1.683 -0.271,2.241 -0.47,2.456c-0.162,0.176 -0.679,0.544 -2.53,0.544c-1.851,0 -2.368,-0.368 -2.53,-0.544c-0.199,-0.215 -0.47,-0.773 -0.47,-2.456c0,-1.657 1.343,-3 3,-3c1.657,0 3,1.343 3,3Zm0.835,3.977c0.879,-0.804 1.165,-2.104 1.165,-3.977c0,-2.761 -2.239,-5 -5,-5c-2.761,0 -5,2.239 -5,5c0,1.873 0.286,3.173 1.165,3.977c-1.664,0.911 -2.969,2.396 -3.649,4.189c-0.124,0.328 -0.153,0.708 0.052,0.993c0.568,0.789 1.674,-0.111 2.13,-0.97c1.007,-1.897 3.004,-3.189 5.302,-3.189c2.298,0 4.295,1.292 5.302,3.189c0.456,0.859 1.562,1.759 2.13,0.97c0.205,-0.285 0.176,-0.665 0.052,-0.993c-0.68,-1.793 -1.985,-3.278 -3.649,-4.189Z' />
    </svg>
  ),
  ExtraMessageIcon: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <path
        d='M20.7414 11.5229C20.96 10.656 21.0005 9.5794 21 8C21.0005 6.4206 20.96 5.344 20.7414 4.47706C20.57 3.68581 20.4733 3.46948 19.9904 3.15288C19.5963 2.85234 18.8701 2.53297 17.4397 2.31262C15.9734 2.08387 14.0894 1.99947 11.5 2C8.91059 1.99947 7.02664 2.08387 5.56035 2.31262C4.12987 2.53297 3.40373 2.85234 3.00961 3.15288C2.52669 3.46948 2.42997 3.68581 2.25863 4.47706C2.03999 5.344 1.99948 6.4206 2 8C2 10.2512 2.13638 11.4423 2.47725 12.174C2.70449 12.6618 3.09917 13.122 4.48824 13.4746C5.33561 13.6897 6 14.4583 6 15.418V16.263L8.90618 14.3255C9.16948 14.1773 9.74784 14.007 10.0507 13.9891C10.5134 13.9965 10.9908 14 11.5 14C14.0894 14.0005 15.9734 13.9161 17.4397 13.6874C18.8701 13.467 19.5963 13.1477 19.9904 12.8471C20.4733 12.5305 20.57 12.3142 20.7414 11.5229ZM23 8C23 14.5 22.0417 16 11.5 16C10.9835 16 10.4899 15.9964 10.0184 15.9888C10.0174 15.9888 10.0164 15.9891 10.0156 15.9896L5.55469 18.9636C4.89014 19.4066 4 18.9302 4 18.1315V15.418C4 15.4157 3.99836 15.4137 3.99614 15.4131C0.43941 14.5103 0 12.4014 0 8C0 1.5 0.958344 0 11.5 0C22.0417 0 23 1.5 23 8Z'
        transform='translate(5 7)'
      />
    </svg>
  ),
  ExtraListIcon: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 30 30' fill='none' name='extraIcon' {...props}>
      <path d='M10 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM25 10c0 .552-.392 1-.875 1h-11.25c-.483 0-.875-.448-.875-1s.392-1 .875-1h11.25c.483 0 .875.448.875 1zM10 16a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM25 16c0 .552-.392 1-.875 1h-11.25c-.483 0-.875-.448-.875-1s.392-1 .875-1h11.25c.483 0 .875.448.875 1zM10 22a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM25 22c0 .552-.392 1-.875 1h-11.25c-.483 0-.875-.448-.875-1s.392-1 .875-1h11.25c.483 0 .875.448.875 1z' />
    </svg>
  ),
  Wells: (props: React.PropsWithRef<any>) => (
    <svg x='0' y='0' viewBox='0 0 512 512' name='extraIcon' {...props}>
      <g>
        <path d='M460.812 190.965c-10.446-.947-20.32 1.932-28.519 7.459l-.041-.059-234.241 97.891c-22.567 7.141-39.641 28.765-41.374 55.748-2.247 35.013 22.16 65.78 54.489 68.713 13.88 1.259 26.983-2.855 37.571-10.691l223.908-119.45c4.278-1.525 8.29-3.688 11.966-6.408l.072-.046c5.614-4.182 10.333-9.694 13.782-16.123 3.471-6.459 5.65-13.834 6.163-21.836 1.807-28.115-17.78-52.841-43.776-55.198zM253.771 359.703c-1.392 21.686-18.743 37.788-38.773 35.974-20.031-1.817-35.127-20.865-33.735-42.548 1.393-21.687 18.743-37.791 38.774-35.974 20.03 1.817 35.128 20.863 33.734 42.548zM137.405 294.813c13.742 2.692 27.081-.013 38.229-6.745l231.819-95.754a42.985 42.985 0 0 0 12.372-5.122l.097-.019v-.027c11.782-7.173 20.468-19.928 22.647-35.744 3.865-27.816-13.844-54.481-39.553-59.531-10.349-2.021-20.366-.182-28.955 4.478l-.02-.056-240.545 73.178c-22.996 4.769-41.611 24.533-45.332 51.252-4.79 34.645 17.244 67.805 49.241 74.09zm-24.801-70.43c2.989-21.457 21.456-35.685 41.284-31.805 19.802 3.904 33.473 24.431 30.484 45.889-2.987 21.456-21.456 35.684-41.284 31.805-19.801-3.905-33.447-24.429-30.484-45.889z'></path>
        <path d='M155.552 310.363c-6.308.428-12.775.092-19.324-1.197-23.885-4.689-43.646-20.496-55.436-41.574l-27.526 8.382c-22.996 4.771-41.585 24.537-45.309 51.254-4.814 34.641 17.22 67.805 49.22 74.092 13.763 2.69 27.104-.013 38.228-6.745l47.912-19.771a83.087 83.087 0 0 1-1.34-30.065c1.821-13.102 6.652-24.736 13.575-34.376zm-51.385 34.61c-2.989 21.456-21.479 35.679-41.284 31.806-19.826-3.908-33.473-24.433-30.484-45.891 2.987-21.456 21.456-35.685 41.282-31.806 19.805 3.878 33.45 24.43 30.486 45.891z'></path>
      </g>
    </svg>
  ),
  TreatmentUnit: (props: React.PropsWithRef<any>) => (
    <svg viewBox='0 0 24 24' name='extraIcon' {...props}>
      <g>
        <path d='M6.5 20a.501.501 0 0 1-.5-.5v-14a.503.503 0 0 1 .544-.498l17 1.5A.5.5 0 0 1 24 7v11a.5.5 0 0 1-.456.498l-17 1.5L6.5 20zM7 6.046v12.908l16-1.412V7.458zM23.5 18h.01z'></path>
        <path d='M6.5 20a.507.507 0 0 1-.121-.015l-6-1.5A.5.5 0 0 1 0 18V7a.5.5 0 0 1 .379-.485l6-1.5a.5.5 0 0 1 .242.97L1 7.391V17.61l5.621 1.405A.5.5 0 0 1 6.5 20z'></path>
        <path d='M3.5 19.25a.5.5 0 0 1-.5-.5V6.25a.5.5 0 0 1 1 0v12.5a.5.5 0 0 1-.5.5zM9.5 18a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 1 0v10a.5.5 0 0 1-.5.5zM13.5 17.5a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 1 0v9a.5.5 0 0 1-.5.5zM17.5 17a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 1 0v8a.5.5 0 0 1-.5.5zM21.5 16.5a.5.5 0 0 1-.5-.5V9a.5.5 0 0 1 1 0v7a.5.5 0 0 1-.5.5z'></path>
      </g>
    </svg>
  ),
  Filters: (props: React.PropsWithRef<any>) => (
    <svg x='0' y='0' viewBox='0 0 512 512' name='extraIcon' {...props}>
      <path d='M470.826 103.099c5.522 0 10-4.477 10-10V45.147c0-5.523-4.478-10-10-10h-18.625l-44.056-26.8A57.118 57.118 0 0 0 378.458 0H134.675a57.144 57.144 0 0 0-29.162 8.032L59.856 35.147H41.174c-5.522 0-10 4.477-10 10v47.952c0 5.523 4.478 10 10 10h11.334v305.438H41.174c-5.522 0-10 4.477-10 10v47.952c0 5.523 4.478 10 10 10h18.068l46.271 27.479A57.144 57.144 0 0 0 134.675 512h243.783a57.125 57.125 0 0 0 29.688-8.347l44.655-27.165h18.025c5.522 0 10-4.477 10-10v-47.952c0-5.523-4.478-10-10-10h-11.334V103.099zm-73.075 383.467c-5.844 3.555-12.516 5.434-19.293 5.434H134.675a37.137 37.137 0 0 1-18.949-5.228l-16.702-9.919h314.694zm-.103-79.128h-21.626v-303.97h21.626zm20-303.97h21.844v303.97h-21.844zm-299.179 0h23.854v303.97h-23.854zm-20 303.97H72.508v-303.97h25.961zm172.073 0h-22.739v-303.97h22.739zm20-303.97h22.74v303.97h-22.74zm-62.739 303.97h-22.74v-303.97h22.74zm-42.74 0h-22.74v-303.97h22.74zm148.219 0v-303.97h22.74v303.97zM115.726 25.228A37.14 37.14 0 0 1 134.675 20h243.783c6.777 0 13.449 1.879 19.293 5.434l15.967 9.713H99.023zM51.174 55.147h409.653v27.952H51.174zm409.652 401.341H51.174v-27.952h409.652z'></path>
    </svg>
  ),
  Pump: (props: React.PropsWithRef<any>) => (
    <svg x='0' y='0' viewBox='0 0 512 512' name='extraIcon' {...props}>
      <g>
        <path d='M502.013 98.67c5.518-.007 9.987-4.482 9.987-10V47.794c0-5.523-4.477-10-10-10H385.64c-17.826 0-33.163 4.742-44.353 13.714-9.312 7.467-20.411 21.785-20.411 47.594v28.494h-2.501c-5.523 0-10 4.477-10 10v42.64h-18.806v-2.728c0-10.501-8.513-19.014-19.014-19.014h-27.637c-10.501 0-19.014 8.513-19.014 19.014v2.727H198.04v-13.669c0-18.55-15.104-33.642-33.668-33.642h-25.03c-18.565 0-33.669 15.092-33.669 33.642v14.099C46.562 185.712 0 235.43 0 295.827 0 356.21 46.518 405.922 105.588 411.01v43.196H10.343c-5.326 0-9.976 4.019-10.321 9.333-.378 5.824 4.235 10.667 9.978 10.667h491.657c5.326 0 9.977-4.019 10.321-9.334.378-5.824-4.235-10.666-9.978-10.666H190.274v-42.759h33.63v2.728c0 10.501 8.513 19.014 19.014 19.014h27.638c10.501 0 19.014-8.513 19.014-19.014v-2.728h140.063c5.523 0 10-4.477 10-10V359.94H502c5.523 0 10-4.477 10-10V241.741c0-5.523-4.477-10-10-10h-62.367v-41.506c0-5.523-4.477-10-10-10h-31.611v-42.64c0-5.523-4.477-10-10-10h-2.501s-.009-25.628.016-26.234c.052-1.294 1.028-2.526 2.323-2.534 2.583-.015 114.153-.157 114.153-.157zm-376.34 67.896c0-7.522 6.132-13.642 13.669-13.642h25.03c7.537 0 13.668 6.119 13.668 13.642v13.669h-52.367zm44.601 287.64h-44.686v-42.759h44.686zm-54.686-62.759C62.88 391.447 20 348.552 20 295.827c0-52.71 42.881-95.592 95.588-95.592h108.316v191.212zM492 251.741v88.199h-52.367v-88.199zm-72.367 98.199v41.507H289.569V340.7c0-5.522-4.478-10-10-10s-10 4.478-10 10v72.488h-25.665V178.494h25.665v72.403c0 5.522 4.478 10 10 10s10-4.478 10-10v-50.662h130.063V349.94zm-91.258-169.705v-32.64h49.647v32.64zm42.902-95.166c-5.253 5.351-5.866 12.481-5.757 18.77v23.757h-24.645V99.102c0-34.142 24.343-41.308 44.764-41.308H492v20.889l-101.569.129c-6.058 0-13.709.71-19.154 6.257z'></path>
        <path d='M188.607 285.827h-26.305c-5.523 0-10 4.478-10 10s4.477 10 10 10h26.305c5.523 0 10-4.478 10-10s-4.477-10-10-10zM188.607 235.852h-26.305c-5.523 0-10 4.478-10 10s4.477 10 10 10h26.305c5.523 0 10-4.478 10-10s-4.477-10-10-10zM188.607 335.831h-26.305c-5.523 0-10 4.478-10 10s4.477 10 10 10h26.305c5.523 0 10-4.478 10-10s-4.477-10-10-10zM279.569 285.799c-5.522 0-10 4.478-10 10v.028c0 5.522 4.478 9.986 10 9.986s10-4.492 10-10.015-4.477-9.999-10-9.999z'></path>
      </g>
    </svg>
  ),
  Equipment: (props: React.PropsWithRef<any>) => (
    <svg x='0' y='0' viewBox='0 0 512 512' name='extraIcon' {...props}>
      <path d='m508.541 259.849-110.387-63.732V116.8a6.92 6.92 0 0 0-3.459-5.991l-156.626-90.43a6.904 6.904 0 0 0-6.919 0L74.52 110.808a6.917 6.917 0 0 0-3.459 5.991v126.626L3.459 282.454A6.922 6.922 0 0 0 0 288.445v131.457a6.92 6.92 0 0 0 3.459 5.991l113.845 65.73a6.907 6.907 0 0 0 6.918 0l151.355-87.387 112.197 64.778a6.907 6.907 0 0 0 6.918 0l113.847-65.73a6.917 6.917 0 0 0 3.459-5.991V265.84a6.913 6.913 0 0 0-3.457-5.991zm-117.307 63.729L291.227 265.84l100.007-57.742 100.009 57.742zm-270.47 22.608-100.007-57.74 100.007-57.74 100.006 57.739zm6.918 11.983 100.007-57.741v115.481l-100.007 57.74zm113.845-146.948 142.788-82.439v67.334L273.93 259.849a6.917 6.917 0 0 0-3.459 5.991v125.369l-28.944 16.711zm-6.919-176.863L377.397 116.8l-142.789 82.438L91.818 116.8zM84.899 128.782l142.791 82.439v65.243l-103.466-59.737a6.904 6.904 0 0 0-6.919 0L84.9 235.436V128.782zM13.838 300.428l100.007 57.74v115.48L13.838 415.907zm270.471-22.606 100.007 57.738v115.48l-100.007-57.742zm113.844 173.219v-115.48l100.009-57.738V393.3z'></path>
    </svg>
  ),
}

export default Icons
