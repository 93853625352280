import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '@/App'
import '@styles/index.css'
import message from 'antd/es/message'
import notification from 'antd/es/notification'
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { Provider } from 'react-redux'
import { store } from './store'
import { appConfig } from './configs/appConfig'

import('@sentry/react').then((Sentry) => {
  Sentry.init({
    dsn: appConfig.SENTRY_DNS,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
})

notification.config({
  placement: 'topLeft',
  top: 30,
  duration: 3,
  rtl: false,
})

message.config({
  top: 30,
  duration: 2,
  maxCount: 3,
  rtl: true,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
