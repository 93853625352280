import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  DASHBOARD_CATEGORY_SECTIONS_BY_PROFIL,
  DASHBORD_COMPONENT_TYPES,
  DASHBORD_PROFILS,
  GRAPHICS_SECTIONS,
} from '@/constants'
import { getMonitoringsLog } from '@/services/monitoringServices'

interface IDashbordPage {
  profil: keyof typeof DASHBOARD_CATEGORY_SECTIONS_BY_PROFIL
  category: DASHBORD_COMPONENT_TYPES
  section: GRAPHICS_SECTIONS
  inlineMode: number
  logBook: (Monitoring & { air_samples: AirSample[] })[]
}

/**
 * Initial state for the dashboard page.
 */
const initialState: IDashbordPage = {
  profil: DASHBORD_PROFILS.PROJECT_MANAGER,
  category: DASHBORD_COMPONENT_TYPES.CHART,
  section: GRAPHICS_SECTIONS.GLOBAL,
  inlineMode: 0,
  logBook: [],
}

/**
 * Thunk action creator to load log data asynchronously.
 */
export const loadLog = createAsyncThunk(
  '/dash/log',
  async (sanitationId: number, { rejectWithValue }) => {
    try {
      const data = await getMonitoringsLog(sanitationId)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing dashboard state and actions.
 */
export const dashboardSlice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    /**
     * Sets the category of the dashboard.
     */
    setCategory: (state, action) => {
      if (
        !DASHBOARD_CATEGORY_SECTIONS_BY_PROFIL[state.profil][
          action.payload as DASHBORD_COMPONENT_TYPES
        ].includes(state.section)
      ) {
        state.section = GRAPHICS_SECTIONS.GLOBAL
      }
      state.category = action.payload
    },
    /**
     * Sets the section of the dashboard.
     */
    setSection: (state, action) => {
      state.section = action.payload
    },
    /**
     * Sets the card mode of the dashboard.
     */
    setCardMode: (state, action) => {
      state.inlineMode = action.payload
    },
    /**
     * Sets the profile of the dashboard.
     */
    setProfl: (state, action) => {
      state.profil = action.payload
    },
  },
})

export default dashboardSlice.reducer
export const { setCategory, setCardMode, setSection, setProfl } = dashboardSlice.actions
