import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fr_translation from '@/locales/fr/fr-FR.json'
import { prefLanguageFromStore } from '@services/storageServices'

const resources = {
  fr: {
    translation: fr_translation,
  },
}

i18n.use(initReactI18next).init({
  lng: prefLanguageFromStore(),
  fallbackLng: prefLanguageFromStore(),
  resources,
  interpolation: {
    escapeValue: false,
  },
})

import('luxon').then(({ DateTime }) => {
  i18n?.services?.formatter?.add('DATE_HUGE', (value, lng = prefLanguageFromStore()) =>
    DateTime.fromJSDate(new Date(value || undefined))
      .setLocale(lng)
      .toLocaleString(DateTime.DATE_HUGE),
  )
  i18n?.services?.formatter?.add('DATE_SHORT', (value, lng = prefLanguageFromStore()) =>
    DateTime.fromJSDate(new Date(value || undefined))
      .setLocale(lng)
      .toLocaleString(DateTime.DATE_SHORT),
  )
  i18n?.services?.formatter?.add('TIME_24_SIMPLE', (value, lng = prefLanguageFromStore()) =>
    DateTime.fromJSDate(new Date(value || undefined))
      .setLocale(lng)
      .toLocaleString(DateTime.TIME_24_SIMPLE),
  )
})

export default i18n
