import airFiltersMeasures from './measures/airFiltersMeasuresSlice'
import authReducer from './authSlice'
import collectorsMeasures from './measures/collectorsMeasuresSlice'
import extractorsMeasures from './measures/extractorsMeasuresSlice'
import languageSlice from './languageSlice'
import prefetchDataSlice from './prefetchDataSlice'
import recoveryPumpsMeasures from './measures/recoveryPumpsMeasuresSlice'
import waterFiltersMeasures from './measures/waterFiltersMeasuresSlice'
import wellsMeasures from './measures/wellsMeasuresSlice'
import treatmentUnitsSlice from './equipments/treatmentUnitsSlice'
import wellsListSlice from './equipments/wellsListSlice'
import filtersListSlice from './equipments/filtersListSlice'
import dashboardSlice from './dashboardSlice'

export default {
  auth: authReducer,
  appLocal: languageSlice,
  prefetch: prefetchDataSlice,
  wellsMeasures,
  airFiltersMeasures,
  waterFiltersMeasures,
  collectorsMeasures,
  extractorsMeasures,
  recoveryPumpsMeasures,
  treatmentUnitsList: treatmentUnitsSlice,
  wellsGroupList: wellsListSlice,
  filters: filtersListSlice,
  dashboard: dashboardSlice,
}
