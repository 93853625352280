import { SANITATIONS_ENDPOINTS } from '@/constants/endpoints'
import { HTTP } from './httpServices'
import { AxiosResponse } from 'axios'

export const createSanitation = (payload: SanitationData): Promise<AxiosResponse<Sanitation>> =>
  HTTP.post(SANITATIONS_ENDPOINTS.LIST, payload)

export const getSanitations = (pagination: any): Promise<PaginateList<Sanitation>> => {
  return HTTP.get(`${SANITATIONS_ENDPOINTS.LIST}`, {
    params: pagination,
  }).then(({ data }) => data)
}

export const searchSanitation = (query: string): Promise<OptionsValue[]> => {
  const pagination: Record<string, unknown> = {
    search: query,
  }
  return getSanitations(pagination as Pagination).then((data) =>
    data.results.map(({ id, worksite, method }) => ({
      label: `${worksite.name} ${method}`,
      value: id,
    })),
  )
}
export const getSanitation = (id: number | string): Promise<Sanitation> =>
  HTTP.get(SANITATIONS_ENDPOINTS.DETAILS(id as number)).then(({ data }) => data)

export const updateSanitation = (
  id: number | string,
  data: SanitationData,
): Promise<AxiosResponse<Sanitation>> => HTTP.put(SANITATIONS_ENDPOINTS.DETAILS(id as number), data)

export const patchSanitation = (
  id: number | string,
  data: unknown,
): Promise<AxiosResponse<Sanitation>> =>
  HTTP.patch(SANITATIONS_ENDPOINTS.DETAILS(id as number), data)

export const deleteSanitation = (id: number): Promise<Sanitation> =>
  HTTP.delete(SANITATIONS_ENDPOINTS.DETAILS(id)).then(({ data }) => data)
