import { MEASURE_POSITION } from '@/constants'
import { combineObjects, initDayJsDate, sanitizedEquipmentData } from '.'
import { readEquipmentTypes } from '@/services/storageServices'

/**
 * Format user data to match UserData interface.
 * @param user The user data.
 * @returns Formatted user data.
 */
export const formatUserData = (user: User): UserData => ({
  ...user,
  groups: user.groups.map((group) => group.name),
})

/**
 * Format monitoring data to match MonitoringData interface.
 * @param operators The list of operators.
 * @param worksite_sanitation The ID of the worksite sanitation.
 * @param date The date of monitoring.
 * @param restProps The rest of the monitoring data.
 * @returns Formatted monitoring data.
 */
export const formatMonitoringData = ({
  operators,
  worksite_sanitation,
  date,
  ...restProps
}: Monitoring): MonitoringData => ({
  ...restProps,
  date: initDayJsDate(date),
  worksite_sanitation: worksite_sanitation.id,
  operators: operators.map(({ id }) => id),
})

/**
 * Format sanitation data to match SanitationData interface.
 * @param sanitation The sanitation data.
 * @returns Formatted sanitation data.
 */
export const formatSanitationData = (sanitation: Sanitation): SanitationData => {
  const { worksite, project_manager, started_at, ended_at, ...rest } = sanitation
  return {
    worksite: worksite.id,
    name: worksite.name,
    code: worksite.code,
    customer: worksite.customer,
    project_manager: project_manager.id,
    period: [initDayJsDate(started_at), initDayJsDate(ended_at)],
    ...rest,
  }
}

/**
 * Determine items to create or remove based on initial and final states.
 * @param initialState The initial state of items.
 * @param finalState The final state of items.
 * @returns Items to create or remove.
 */
export const getItemsToCreateOrRemove = (initialState: AddRemoveDic[], finalState: number[]) => {
  const itemsToRemove = initialState
    .filter(({ item }) => !finalState.includes(item?.id))
    .map((item) => item.associationId)
  const itemsToCreate = finalState.filter(
    (id) => !initialState.map(({ item }) => item?.id).includes(id),
  )
  return itemsToRemove.length ? { itemsToRemove, itemsToCreate } : { itemsToCreate }
}

/**
 * Prepare sanitation data for API request.
 * @param sanitationData The sanitation data.
 * @returns Prepared sanitation data.
 */
export const prepareSanitationData = (sanitationData: SanitationData): SanitationData => {
  const prepareData: SanitationData = {
    ...sanitationData,
    started_at: sanitationData.period[0],
    ended_at: sanitationData.period[1],
  }
  return prepareData
}

/**
 * Create equipment data object.
 * @param equipment The equipment data.
 * @returns Equipment data object.
 */
export function createEquipmentData(equipment: EquipmentData) {
  return { equipment }
}

/**
 * Create treatment unit payload for API request.
 * @param id The ID of the treatment unit.
 * @param worksite_sanitation The ID of the worksite sanitation.
 * @param extractorCollector The data of extractor and collector.
 * @param identifier The identifier of the treatment unit.
 * @param recoveryPumps The data of recovery pumps.
 * @returns Treatment unit payload.
 */
export function createTreatmentUnitPayload({
  id,
  worksite_sanitation,
  extractorCollector,
  identifier,
  recoveryPumps,
}: TreatmentUnitData) {
  const unitData = { worksite_sanitation, identifier, id }

  const identifiersMapCount: any = {}

  const groupsAndExtractorCollectorData = extractorCollector?.map(
    ({ collector, extractor, groupId, treatment_unit }) => {
      const groupData = {
        id: groupId,
        number: `${extractor?.identifier}-${collector?.identifier}`,
        worksite_sanitation: worksite_sanitation,
        treatment_unit,
      }
      if (identifiersMapCount[extractor?.identifier]) identifiersMapCount[extractor?.identifier] += 1
      else identifiersMapCount[extractor?.identifier] = 1

      if (identifiersMapCount[collector?.identifier]) identifiersMapCount[collector?.identifier] += 1
      else identifiersMapCount[collector?.identifier] = 1

      const extractorEquipment = {
        ...sanitizedEquipmentData({
          ...extractor,
          equipment_type: readEquipmentTypes()?.extractor.id,
        } as BuildedSanitationEquipment),
      }

      const collectorEquipment = {
        ...sanitizedEquipmentData({
          ...collector,
          equipment_type: readEquipmentTypes()?.collector.id,
        } as BuildedSanitationEquipment),
      }

      return { groupData, extractorCollector: [extractorEquipment, collectorEquipment] }
    },
  )
  const recoveryPumpsData: BuildedSanitationEquipment[] =
    recoveryPumps?.map((pump) => {
      if (identifiersMapCount[pump.identifier]) identifiersMapCount[pump.identifier] += 1
      else identifiersMapCount[pump.identifier] = 1
      return {
        ...sanitizedEquipmentData({
          ...pump,
          equipment_type: readEquipmentTypes()?.recovery_pump.id,
        }),
      }
    }) ?? []

  return {
    unitData,
    groupsAndExtractorCollectorData,
    recoveryPumpsData,
    duplicatedIdentifiers: Object.keys(identifiersMapCount).filter(id => identifiersMapCount[id] > 1)
  }
}

/**
 * Generate measurement data based on entries, equipment, monitoring, and position.
 * @param entries The entries data.
 * @param equipment The equipment ID or name.
 * @param monitoring The monitoring ID or name.
 * @param position The measurement position.
 * @returns Generated measurement data.
 */
export const generateMeasurementData = (
  entries: Record<string, any>,
  equipment: string | number,
  monitoring: string | number,
  position: MEASURE_POSITION,
) =>
  Object.values(entries).map(
    combineObjects({
      equipment,
      monitoring,
      position,
    }),
  )
