import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setSession, readToken, unsetSession } from '@services/storageServices'
import { confirmAccountRecovering, initiateAccountRecovering, login } from '@services/authServices'
interface IAuthState {
  token: string | null
}

const initialState: IAuthState = {
  token: readToken('access'),
}

/**
 * Thunk action creator to perform authentication (login) asynchronously.
 */
export const doAuth = createAsyncThunk(
  '/doAuth/login',
  async (loginPayload: LoginData, { rejectWithValue }) => {
    try {
      const res = await login(loginPayload)
      setSession(res)
      return res.access
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Thunk action creator to initiate account recovery asynchronously.
 */
export const recoverAccount = createAsyncThunk(
  '/doAuth/recover',
  async (loginPayload: { email: string }, { rejectWithValue }) => {
    try {
      const res = await initiateAccountRecovering(loginPayload)
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Thunk action creator to confirm account recovery asynchronously.
 */
export const recoverAccountConfirm = createAsyncThunk(
  '/doAuth/recover/confirm',
  async (
    loginPayload: { new_password: string; uid: string; token: string },
    { rejectWithValue },
  ) => {
    try {
      await confirmAccountRecovering(loginPayload)
      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Thunk action creator to perform logout asynchronously.
 */
export const logout = createAsyncThunk('/doAuth/logout', async (_payload, { rejectWithValue }) => {
  try {
    unsetSession()
    return true
  } catch (error) {
    return rejectWithValue(error)
  }
})

/**
 * Slice for managing authentication state and actions.
 */
export const authSlice = createSlice({
  name: 'doAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doAuth.fulfilled, (state, action) => {
      state.token = action.payload
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.token = null
    })
    builder.addCase(recoverAccount.fulfilled, (state) => {
      return state
    })
  },
})

export default authSlice.reducer
