import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createFilterMap, customAlphanumericSorter } from '@/utils'
import { getSanitation } from '@/services/Sanitation'

const initialState: BuildedSanitationEquipment[] = []
/**
 * Thunk action creator to fetch sanitation filters asynchronously.
 * @param sanitation The sanitation object.
 */
export const fetchFilters = createAsyncThunk(
  '/fetch/filters',
  async ({ sanitation }: { sanitation: Sanitation }, { rejectWithValue }) => {
    try {
      const newSanitation = await getSanitation(sanitation.id)
      const filtersMap = createFilterMap(newSanitation.equipments)
      const buildedData = [...filtersMap.values()].sort((a, b) =>
        customAlphanumericSorter(a.identifier, b.identifier),
      )
      return buildedData
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing sanitation filters state and actions.
 */
export const sanitationFilters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    deleteFilter: (state, action: PayloadAction<number>) => {
      return state.filter(({ associationKey }) => associationKey !== action.payload)
    },
    updateFilter: (
      state,
      action: PayloadAction<{ position: number; field: string; value: any }>,
    ) => {
      const { position, value, field } = action.payload
      return state.map((filter, index) => {
        if (index === position) {
          return { ...filter, [field]: value }
        }
        return filter
      })
    },
    addFilter: (state, action: PayloadAction<any>) => {
      state.push(action.payload)
    },
    setFiltersFromSanitation: (_, action: PayloadAction<Sanitation>) => {
      const filtersMap = createFilterMap(action.payload.equipments)
      return [...filtersMap.values()].sort((a, b) =>
        customAlphanumericSorter(a.identifier, b.identifier),
      )
    },
    setFilters: (_, action: PayloadAction<BuildedSanitationEquipment[]>) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFilters.fulfilled,
      (_, action: PayloadAction<BuildedSanitationEquipment[]>) => {
        return action.payload
      },
    )
  },
})

export const { deleteFilter, setFilters, addFilter, setFiltersFromSanitation, updateFilter } =
  sanitationFilters.actions
export default sanitationFilters.reducer
