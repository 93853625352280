import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSanitationEquipmentMeasure } from '@/services/sanitationEquipmentMeasureServices'
import { EQUIPMENTS_CATEGORIES } from '@/constants'
import {
  buildEquipmentMeasureDictionary,
  sortMeasuresByLastValue,
  updateAirSample,
  updateMeasureValue,
} from '@/utils'
import { readEquipmentTypes } from '@/services/storageServices'

type FetchEquipmentMeasuresProps = {
  sanitationId: string | number
  monitoringId: string | number
  sanitation: Sanitation
}

const initialState: EquipmentMeasuresRecord[] = []

/**
 * Thunk action creator to fetch water filters measures asynchronously.
 * @param sanitationId The ID of the sanitation.
 * @param monitoringId The ID of the monitoring.
 * @param sanitation The sanitation object.
 */
export const fetchWaterFiltersMeasures = createAsyncThunk(
  '/prefetch/waterFiltersMeasure',
  async (
    { sanitationId, monitoringId, sanitation }: FetchEquipmentMeasuresProps,
    { rejectWithValue },
  ) => {
    try {
      const { results } = await getSanitationEquipmentMeasure(sanitationId, monitoringId, {
        equipment_type: EQUIPMENTS_CATEGORIES.WATER_FILTER,
      })
      return {
        data: results,
        sanitationId,
        monitoringId,
        sanitation,
        equipmentType: readEquipmentTypes().water_filter.id,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing water filters measures state and actions.
 */
export const waterFiltersMeasures = createSlice({
  name: 'waterFiltersMeasures',
  initialState,
  reducers: {
    sortWaterFiltersMeasuresByLastValue: sortMeasuresByLastValue,
    updateWaterFiltersMeasuresParamValue: updateMeasureValue,
    updateWaterFiltersAirSamples: updateAirSample,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchWaterFiltersMeasures.fulfilled,
      (
        _,
        action: PayloadAction<{
          data: SanitationEquipmentMeasure[]
          sanitationId: number | string
          monitoringId: number | string
          equipmentType: number
          sanitation: Sanitation
        }>,
      ) => {
        return buildEquipmentMeasureDictionary(action.payload)
      },
    )
  },
})

export const {
  sortWaterFiltersMeasuresByLastValue,
  updateWaterFiltersMeasuresParamValue,
  updateWaterFiltersAirSamples,
} = waterFiltersMeasures.actions

export default waterFiltersMeasures.reducer
