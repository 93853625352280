import { Env } from '@utils/env'

type AppConfig = {
  NODE_ENV: string
  APP_NAME: string
  STORAGE_SECRET: string
  STORAGE_TYPE: 'localStorage' | 'sessionStorage'
  REQUEST_TIMEOUT: number
  TOKEN_TYPE: 'Bearer' | 'Basic' | 'JWT'
  API_PROTOCOL: 'http' | 'https'
  API_HOSTNAME: string
  API_PORT: number
  API_ENDPOINTS_PREFIX: string
  API_BASE_URL: () => string
  REQUEST_HEADER_AUTH_KEY: string
  USE_ENCRYPT_STORE: boolean
  UPLOAD_SIZE_LIMIT: number
  SENTRY_DNS: string
}

export const appConfig: AppConfig = {
  NODE_ENV: Env('NODE_ENV', 'development'),
  APP_NAME: Env('APP_TITLE', 'Valgo-Fuji'),
  STORAGE_SECRET: Env('STORAGE_SECRET', '%defaultSecret'),
  STORAGE_TYPE: Env('STORAGE_TYPE', 'sessionStorage'),
  REQUEST_TIMEOUT: Env('REQUEST_TIMEOUT', 500000),
  TOKEN_TYPE: Env('TOKEN_TYPE', 'Basic'),
  API_PROTOCOL: Env('API_PROTOCOL', 'https'),
  API_HOSTNAME: Env('API_HOSTNAME', 'fasfox.net'),
  API_PORT: Env('API_PORT', ''),
  API_ENDPOINTS_PREFIX: Env('API_ENDPOINTS_PREFIX', 'api'),
  API_BASE_URL: function (): string {
    return `${this.API_PROTOCOL}://${this.API_HOSTNAME}${
      this.API_PORT ? `:${this.API_PORT}` : ''
    }/${this.API_ENDPOINTS_PREFIX}`
  },
  REQUEST_HEADER_AUTH_KEY: Env('REQUEST_HEADER_AUTH_KEY', 'Authorization'),
  USE_ENCRYPT_STORE: Env('USE_ENCRYPT_STORE', true) === 'false' ? false : true,
  UPLOAD_SIZE_LIMIT: Env('UPLOAD_SIZE_LIMIT', 10000),
  SENTRY_DNS: Env('SENTRY_DNS', ''),
}