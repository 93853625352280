import FormFieldElement from '@/components/UI/FormFieldElement'
import { PATHS } from '@/routes/paths'
import { useAppDispatch } from '@/store'
import { recoverAccountConfirm } from '@/store/slices/authSlice'
import { parseServerError } from '@/utils/errors'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import notification from 'antd/es/notification'
import Button from 'antd/es/button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

const passwordResetInputs: FormField<ResetPasswordData>[] = [
  {
    name: 'new_password',
    input: {
      component: Input.Password,
    },
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 're_new_password',
    input: {
      component: Input.Password,
    },
    rules: (t) => [
      {
        required: true,
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('new_password') === value) {
            return Promise.resolve()
          }
          return Promise.reject(t('inputs.re_new_password.validation.noMatch'))
        },
      }),
    ],
  },
]

const FormRecoverAccountConfirm = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { uid, token } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const handleFormSubmit = async (data: { new_password: string }) => {
    setIsLoading(true)
    try {
      if (token && uid) {
        await dispatch(recoverAccountConfirm({ ...data, uid, token })).unwrap()
        navigate(PATHS.AUTH.LOGIN_PATH)
      }
    } catch (err: any) {
      const parsedErrors = parseServerError(err.response?.data)
      form.setFields(parsedErrors)
      for (const { name, errors } of parsedErrors)
        if (name === 'uid' || name == 'token') {
          notification.destroy()
          notification.error({ message: errors.join('') })
        }
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className='mx-auto min-w-[500px] max-w-screen-sm p-8 px-12'>
      <Form form={form} layout='vertical' onFinish={handleFormSubmit} className='space-y-4'>
        {passwordResetInputs.map((formField, index) => (
          <FormFieldElement key={index} {...formField} />
        ))}
        <Form.Item>
          <Button loading={isLoading} className='mb-8 w-full' type='primary' htmlType='submit'>
            {t('action.recover_account')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default FormRecoverAccountConfirm
