import Dropdown from 'antd/es/dropdown'
import Icons from './UI/Icons'
import { Link } from 'react-router-dom'
import { readUser } from '@/services/storageServices'
import { PATHS } from '@/routes/paths'
import { useTranslation } from 'react-i18next'

export default function UserDropdown() {
  const { t } = useTranslation()

  return (
    <Dropdown
      destroyPopupOnHide
      trigger={['click', 'hover']}
      menu={{
        unselectable: 'on',
        items: [
          {
            style: {
              padding: 0,
            },
            label: (
              <div className='grid grid-flow-row items-center rounded-xl bg-white'>
                <p className='border-b p-4'>
                  {t('app.great')},&nbsp;
                  <span className='font-bold first-letter:uppercase'>
                    {readUser().user?.first_name || readUser().user?.username}
                  </span>
                </p>
                <Link
                  to={PATHS.SETTING}
                  state={{
                    from: window.location.pathname,
                  }}
                  data-cy='goToSetting'
                  className='flex items-center gap-x-3 p-3 text-sm font-medium'
                >
                  <Icons.CogIcon className='h-5' />
                  {t('action.setting')}
                </Link>
                <Link
                  to={PATHS.AUTH.LOGOUT}
                  data-cy='logoutLink'
                  state={{
                    from: window.location.pathname,
                  }}
                  className='flex items-center gap-x-3 p-3 text-sm font-medium text-red-400 hover:text-red-400'
                >
                  <Icons.PowerIcon className='h-5' />
                  {t('action.logout')}
                </Link>
              </div>
            ),
            key: 1,
          },
        ],
      }}
    >
      <div
        data-cy='userDropdown'
        className='flex size-11 items-center justify-center rounded-xl bg-white ring-4 ring-slate-200'
        role='button'
        tabIndex={0}
      >
        <Icons.UserIcon className='size-9 p-2 text-brand' />
      </div>
    </Dropdown>
  )
}
