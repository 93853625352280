import { USER_ROLES } from '@/constants'

export const PATHS = {
  REDIRECT: {
    FORBIDDEN: '/forbidden',
    SERVERERROR: '/serverFailed',
    RESSOURCENOTFOUND: '*',
  },
  AUTH: {
    LAYER: '/auth/*',
    LOGIN_PATH: '/auth/login',
    LOGIN_ROUTE: '/login',
    LOGOUT: '/logout',
    RECOVER_ACCOUNT_PATH: '/auth/recover_account',
    RECOVER_ACCOUNT_ROUTE: '/recover_account',
    CONFIRM_ACCOUNT_RECOVER_PATH: '/auth/recover_account_confirm/:uid/:token',
    CONFIRM_ACCOUNT_RECOVER_ROUTE: '/recover_account_confirm/:uid/:token',
  },
  SETTING: '/settings',
  DASHBOARD_CLIENT: {
    /**
     * Generates a link for the client dashboard.
     * @param id The ID of the dashboard.
     * @param baseUrl The base URL for the link.
     * @returns The generated link.
     */
    LINK: (id?: number, baseUrl?: string) =>
      `/${baseUrl?.split('/')[1] || 'sanitations_in_progress'}/${id || ':id'}/customer_dashboard`,
    /**
     * Generates a secondary link for the client dashboard.
     * @param id The ID of the dashboard.
     * @param baseUrl The base URL for the link.
     * @returns The generated secondary link.
     */
    LINK2: (id?: number, baseUrl?: string) =>
      `/${baseUrl?.split('/')[1] || 'sanitations_completed'}/${id || ':id'}/customer_dashboard`,
    ACCESS: {
      permissions: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.CUSTOMER],
    },
  },
  DASHBOARD_PROJECT_MANAGER: {
    /**
     * Generates a link for the project manager dashboard.
     * @param id The ID of the dashboard.
     * @param baseUrl The base URL for the link.
     * @returns The generated link.
     */
    LINK: (id?: number, baseUrl?: string) =>
      `/${baseUrl?.split('/')[1] || 'sanitations_in_progress'}/${id || ':id'}/dashboard`,
    /**
     * Generates a secondary link for the project manager dashboard.
     * @param id The ID of the dashboard.
     * @param baseUrl The base URL for the link.
     * @returns The generated secondary link.
     */
    LINK2: (id?: number, baseUrl?: string) =>
      `/${baseUrl?.split('/')[1] || 'sanitations_completed'}/${id || ':id'}/dashboard`,
    ACCESS: {
      permissions: [USER_ROLES.PROJECT_MANAGER],
    },
  },
  SANITATIONS: {
    ACCESS: {
      permissions: [],
    },
    IN_PROGRESS_LIST: {
      LINK: '/sanitations_in_progress',
      ACCESS: {
        permissions: [],
      },
    },
    COMPLETED_LIST: {
      LINK: '/sanitations_completed',
      ACCESS: {
        permissions: [],
      },
    },
    CREATE: {
      LINK: '/new_sanitation',
      ACCESS: {
        permissions: [USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER],
      },
    },
    UPDATE: {
      /**
       * Generates a link for updating a sanitation.
       * @param id The ID of the sanitation.
       * @param baseUrl The base URL for the link.
       * @returns The generated link.
       */
      LINK: (id?: number, baseUrl?: string) =>
        `/${baseUrl?.split('/')[1] || 'sanitations_in_progress'}/${id || ':id'}/update`,
      LINK2: (id?: number, baseUrl?: string) =>
        `/${baseUrl?.split('/')[1] || 'sanitations_completed'}/${id || ':id'}/update`,
      ACCESS: {
        permissions: [USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER],
      },
    },
    MONITORING: {
      /**
       * Generates a link for monitoring a sanitation.
       * @param id The ID of the sanitation.
       * @param baseUrl The base URL for the link.
       * @returns The generated link.
       */
      LINK: (id?: number, baseUrl?: string) =>
        `${baseUrl || '/sanitations_in_progress'}/${id || ':id'}/monitorings`,
      ACCESS: {
        permissions: [USER_ROLES.ADMIN, USER_ROLES.OPERATOR, USER_ROLES.PROJECT_MANAGER],
      },
      LINK2: (id?: number, baseUrl?: string) =>
        `${baseUrl || '/sanitations_completed'}/${id || ':id'}/monitorings`,
    },
    MEASURES: {
      /**
       * Generates a link for viewing measures of a sanitation.
       * @param sanitationId The ID of the sanitation.
       * @param monitoringId The ID of the monitoring.
       * @param baseUrl The base URL for the link.
       * @returns The generated link.
       */
      LINK: (sanitationId?: number, monitoringId?: number, baseUrl?: string) =>
        `/${baseUrl?.split('/')[1] || 'sanitations_in_progress'}/${
          sanitationId || ':sanitationId'
        }/monitorings/${monitoringId || ':monitoringId'}/measures`,
      LINK2: (sanitationId?: number, monitoringId?: number, baseUrl?: string) =>
        `/${baseUrl?.split('/')[1] || 'sanitations_completed'}/${
          sanitationId || ':sanitationId'
        }/monitorings/${monitoringId || ':monitoringId'}/measures`,
      ACCESS: {
        permissions: [USER_ROLES.ADMIN, USER_ROLES.OPERATOR, USER_ROLES.PROJECT_MANAGER],
      },
    },
    LABO_RESULTS: {
      /**
       * Generates a link for laboratory results of a sanitation.
       * @param id The ID of the sanitation.
       * @param baseUrl The base URL for the link.
       * @returns The generated link.
       */
      LINK: (id?: number, baseUrl?: string) =>
        `/${baseUrl?.split('/')[1] || 'sanitations_in_progress'}/${id || ':id'}/labo_results`,
      LINK2: (id?: number, baseUrl?: string) =>
        `/${baseUrl?.split('/')[1] || 'sanitations_completed'}/${id || ':id'}/labo_results`,
      ACCESS: {
        permissions: [USER_ROLES.ADMIN, USER_ROLES.OPERATOR, USER_ROLES.PROJECT_MANAGER],
      },
    },
  },
  USERS: {
    LIST: {
      /**
       * Link to the user list.
       */
      LINK: '/users',
      ACCESS: {
        requireAll: true,
        permissions: [USER_ROLES.ADMIN],
      },
    },
    CREATE: {
      /**
       * Link to create a new user.
       */
      LINK: '/new_user',
      ACCESS: {
        requireAll: true,
        permissions: [USER_ROLES.ADMIN],
      },
    },
    UPDATE: {
      /**
       * Generates a link for updating a user.
       * @param id The ID of the user.
       * @returns The generated link.
       */
      LINK: (id?: number) => `/user/${id}`,
      ACCESS: {
        requireAll: true,
        permissions: [USER_ROLES.ADMIN],
      },
    },
  },
}

export const FIRST_PAGE_TO_VISIT = PATHS.SANITATIONS.IN_PROGRESS_LIST.LINK
