import ButtonIcon from '@/components/UI/ButtonIcon'
import FormFieldElement from '@/components/UI/FormFieldElement'
import Icons from '@/components/UI/Icons'
import { useAppDispatch } from '@/store'
import { recoverAccount } from '@/store/slices/authSlice'
import { parseServerError } from '@/utils/errors'
import Form from 'antd/es/form'
import message from 'antd/es/message'
import Button from 'antd/es/button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const FormRecoverAccount = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const handleFormSubmit = async (data: { email: string }) => {
    try {
      setIsLoading(true)
      await dispatch(recoverAccount(data)).unwrap()
      setIsSuccess(true)
      message.success({ content: t('httpResponseMessage.200') })
    } catch (err: any) {
      form.setFields(parseServerError(err.response.data))
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className='mx-auto min-w-[500px] max-w-screen-sm p-8 px-12'>
      <div className='relative bottom-8 flex'>
        <ButtonIcon Icon={Icons.ArrowLeftIcon} onClick={() => navigate(-1)} />
      </div>

      <Form
        form={form}
        hidden={isSuccess}
        layout='vertical'
        onFinish={handleFormSubmit}
        className='space-y-10'
      >
        {[
          {
            name: 'email',
            rules: [{ required: true }],
          },
        ].map((formField, index) => (
          <FormFieldElement key={index} {...formField} />
        ))}
        <Form.Item>
          <Button loading={isLoading} className='-mt-8 w-full' type='primary' htmlType='submit'>
            {t('action.recover_account')}
          </Button>
        </Form.Item>
      </Form>
      <div hidden={!isSuccess} className='text-base font-light text-slate-600'>
        <Icons.AirPlaneMail className='relative bottom-4 mx-auto h-12 text-brand opacity-80' />
        <p>
          {t('message.recover_account_mail_sent')}
          <span className='ml-1 inline-flex text-sm font-medium tracking-wider text-brand'>
            {form.getFieldValue('email')}
          </span>
        </p>
        <p className='mt-3 flex items-center justify-between text-sm'>
          <span> {t('message.info_retry_recovering')} </span>
          <span
            data-cy='retryMail'
            onClick={form.submit}
            className='flex cursor-pointer items-center'
          >
            <Icons.ReloadIcon className={`h-6 text-brand ${isLoading ? 'animate-spin' : ''} `} />
            {t('action.retry')}
          </span>
        </p>
      </div>
    </div>
  )
}

export default FormRecoverAccount
