import { enumToChoices, formatCustomRules } from '@/utils'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import { useTranslation } from 'react-i18next'

function FormFieldElement({
  name,
  rules,
  input,
  label,
  noLabel,
  invisible,
  ...otherProps
}: FormField<Record<string, unknown>>) {
  const { t } = useTranslation()
  const options = input?.props?.options
  const labelText = t(`inputs.${label ?? (typeof name == 'string' ? name : name.join('.'))}.label`)
  const formattedRules = formatCustomRules(t, rules)
  if (invisible) return <Form.Item name={name} hidden noStyle />
  return (
    <Form.Item name={name} label={noLabel ? '' : labelText} rules={formattedRules} {...otherProps}>
      {input?.component ? (
        <input.component
          {...input?.props}
          options={
            Array.isArray(options)
              ? options
              : enumToChoices(options, Array.isArray(name) ? name[name.length - 1] : name).map(
                  ({ label, value }) => ({
                    label: t(label),
                    value,
                  }),
                )
          }
        />
      ) : (
        <Input {...input?.props} />
      )}
    </Form.Item>
  )
}

export default FormFieldElement
