import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSanitationEquipmentMeasure } from '@/services/sanitationEquipmentMeasureServices'
import { EQUIPMENTS_CATEGORIES, INFINITE } from '@/constants'
import {
  buildEquipmentMeasureDictionary,
  sortMeasuresByLastValue,
  updateAirSample,
  updateMeasureValue,
} from '@/utils'
import { readEquipmentTypes } from '@/services/storageServices'

type FetchEquipmentMeasuresProps = {
  sanitationId: string | number
  monitoringId: string | number
  sanitation: Sanitation
}

const initialState: EquipmentMeasuresRecord[] = []

/**
 * Thunk action creator to fetch well measures asynchronously.
 * @param sanitationId The ID of the sanitation.
 * @param monitoringId The ID of the monitoring.
 * @param sanitation The sanitation object.
 */
export const fetchWellsMeasures = createAsyncThunk(
  '/prefetch/wellsMeasure',
  async (
    { sanitationId, monitoringId, sanitation }: FetchEquipmentMeasuresProps,
    { rejectWithValue },
  ) => {
    try {
      const { results } = await getSanitationEquipmentMeasure(sanitationId, monitoringId, {
        equipment_type: EQUIPMENTS_CATEGORIES.MEASURE_WELL,
        limit: INFINITE,
      })
      return {
        sanitationId,
        monitoringId,
        sanitation,
        data: results,
        equipmentType: readEquipmentTypes().measure_well.id,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing well measures state and actions.
 */
export const wellsMeasures = createSlice({
  name: 'wellsMeasure',
  initialState,
  reducers: {
    sortWellsMeasuresByLastValue: sortMeasuresByLastValue,
    updateWellsMeasuresParamValue: updateMeasureValue,
    updateWellsAirSamples: updateAirSample,
    filterByEquipmentGroup: (state, action) => {
      return state.map((item) => {
        return { ...item, masked: action.payload ? item.group.id !== action.payload : false }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchWellsMeasures.fulfilled,
      (
        _,
        action: PayloadAction<{
          data: SanitationEquipmentMeasure[]
          sanitationId: number | string
          monitoringId: number | string
          sanitation: Sanitation
          equipmentType: number
        }>,
      ) => {
        return buildEquipmentMeasureDictionary(action.payload).sort(({ group: A }, { group: B }) =>
          A.number.localeCompare(B.number),
        )
      },
    )
  },
})

export const {
  sortWellsMeasuresByLastValue,
  updateWellsMeasuresParamValue,
  filterByEquipmentGroup,
  updateWellsAirSamples,
} = wellsMeasures.actions

export default wellsMeasures.reducer
