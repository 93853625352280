import { SANITATION_EQUIPMENT_MEASURES } from '@/constants/endpoints'
import { HTTP } from './httpServices'

export const getSanitationEquipmentMeasure = (
  sanitationId: string | number,
  monitoringId: string | number,
  pagination?: Record<string, unknown>,
): Promise<PaginateList<SanitationEquipmentMeasure>> => {
  return HTTP.get(`${SANITATION_EQUIPMENT_MEASURES.LIST(sanitationId, monitoringId)}`, {
    params: pagination,
  }).then(({ data }) => data)
}
