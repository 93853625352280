import axios, { AxiosError, AxiosResponse } from 'axios'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { appConfig } from '@/configs/appConfig'
import { AUTH_ENDPOINTS } from '@/constants/endpoints'
import { prefLanguageFromStore } from './storageServices'
import { HTTP, responseErrorLogger } from './httpServices'

// Interceptor to handle response errors
axios.interceptors.response.use(undefined, (error: AxiosError<any>) => {
  responseErrorLogger(error)
  throw error
})

// Interceptor to set the preferred language in request headers
axios.interceptors.request.use(
  async (request) => {
    request.headers['Accept-Language'] = prefLanguageFromStore()
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Function to make a login request
export const login = (loginPayload: LoginData): Promise<LogedUser> =>
  axios
    .post<LogedUser>(`${appConfig.API_BASE_URL()}/${AUTH_ENDPOINTS.LOGIN}`, {
      ...loginPayload,
    })
    .then(({ data }) => data)

// Function to make a login request
export const initiateAccountRecovering = (loginPayload: { email: string }): Promise<LogedUser> =>
  axios
    .post<LogedUser>(`${appConfig.API_BASE_URL()}/${AUTH_ENDPOINTS.RECOVER_ACCOUNT}`, {
      ...loginPayload,
    })
    .then(({ data }) => data)

export const confirmAccountRecovering = (loginPayload: {
  uid: string
  token: string
  new_password: string
}): Promise<LogedUser> =>
  axios
    .post<LogedUser>(`${appConfig.API_BASE_URL()}/${AUTH_ENDPOINTS.CONFIRM_RECOVER_ACCOUNT}`, {
      ...loginPayload,
    })
    .then(({ data }) => data)

// Function to make a logout request
export const logout = (): Promise<AxiosResponse> => axios.post<undefined>(AUTH_ENDPOINTS.LOGOUT)

// Function to refresh the token
export const refreshSession = (refresh: string) =>
  axios
    .post<{ access: string }>(`${appConfig.API_BASE_URL()}/${AUTH_ENDPOINTS.REFRESH_TOKEN}`, {
      refresh,
    })
    .then(({ data }) => data)

// Function to reset loged user password
export const resetPassword = (resetData: ResetPasswordData) =>
  HTTP.post<ResetPasswordData>(AUTH_ENDPOINTS.RESET_PASSWORD, resetData).then(({ data }) => data)

// Function to check if the token is expired
export const isTokenExpired = (token: string): boolean => {
  const decodedToken = jwt_decode<JwtPayload>(token)
  if (decodedToken && decodedToken.exp) {
    if (decodedToken.exp < Date.now() / 1000) {
      return true
    }
  }
  return false
}
