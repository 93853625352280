import axios, { AxiosError, AxiosInstance } from 'axios'
import { appConfig } from '@/configs/appConfig'
import { persistToken, prefLanguageFromStore, readToken, unsetSession } from './storageServices'
import { RESPONSE_STATUS } from '@constants/httpSatus'
import { isTokenExpired, refreshSession } from './authServices'
import notification from 'antd/es/notification'
import i18next from 'i18next'
import { DEFAULT_TIMEOUT } from '@/constants'

export const HTTP: AxiosInstance = axios.create({
  baseURL: appConfig.API_BASE_URL(),
  timeout: appConfig.REQUEST_TIMEOUT,
})

HTTP.defaults.headers.post['Content-Type'] = 'application/json'

HTTP.interceptors.request.use(
  async (request) => {
    const preferredLanguage = prefLanguageFromStore()
    request.headers['Accept-Language'] = preferredLanguage
    let accessToken = readToken('access')
    if (accessToken && isTokenExpired(accessToken)) {
      const refreshToken = readToken('refresh')
      if (refreshToken)
        try {
          const { access } = await refreshSession(refreshToken)
          persistToken('access', access)
          accessToken = access
        } catch (refreshError) {
          notification.error({ message: i18next.t('action.logout') })
        }
    }
    if (accessToken)
      request.headers[appConfig.REQUEST_HEADER_AUTH_KEY] = `${appConfig.TOKEN_TYPE} ${accessToken}`
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

HTTP.interceptors.response.use(undefined, (error: AxiosError<unknown>) => {
  responseErrorLogger(error, true)
  throw error
})

export function responseErrorLogger(error: AxiosError<any>, checkAuth?: boolean) {
  const { response, code } = error
  if (
    response?.status &&
    [
      // RESPONSE_STATUS.BAD_REQUEST,
      RESPONSE_STATUS.FORBIDDEN,
      RESPONSE_STATUS.SERVER_ERROR,
      RESPONSE_STATUS.NOT_FOUND,
      RESPONSE_STATUS.UNAUTHORIZED,
      RESPONSE_STATUS.FORBIDDEN,
    ].includes(response.status)
  ) {
    if (response?.data?.detail) {
      notification.error({
        message: response?.data?.detail,
      })
    } else {
      notification.error({
        message: i18next.t(`httpResponseMessage.${response.status}`),
      })
    }
  }
  if (code === 'ERR_NETWORK') {
    notification.error({
      message: i18next.t(`httpResponseMessage.0`),
    })
    return
  }
  if (response?.status === RESPONSE_STATUS.UNAUTHORIZED && checkAuth) {
    // We set a timeout to let user read the notification about the fact that he will be automatically disconnected.
    const id = setTimeout(() => {
      unsetSession()
      window.location.replace('/')
      clearTimeout(id)
    }, DEFAULT_TIMEOUT)
  }
}
