import { SANITATION_MONITORING } from '@/constants/endpoints'
import { HTTP } from './httpServices'
import { AxiosResponse } from 'axios'
import { parseServerError } from '@/utils/errors'

export const creatMonitoring = (
  sanitationId: number | string,
  payload: MonitoringData,
): Promise<AxiosResponse<Monitoring>> =>
  HTTP.post(SANITATION_MONITORING.LIST(sanitationId as number), payload)

export const getMonitorings = (
  sanitationId: number | string,
  pagination?: Pagination,
): Promise<PaginateList<Monitoring>> => {
  return HTTP.get(`${SANITATION_MONITORING.LIST(sanitationId as number)}`, {
    params: pagination,
  }).then(({ data }) => data)
}

export const getMonitoringsLog = (
  sanitationId: number | string,
  pagination?: Pagination,
): Promise<
  PaginateList<
    Monitoring & {
      air_samples: (AirSample & {
        equipment: AssociatedEquipment & { equipment_type: EquipmentType }
      })[]
    }
  >
> => {
  return HTTP.get(`${SANITATION_MONITORING.LOG(sanitationId as number)}`, {
    params: pagination,
  }).then(({ data }) => data)
}

export const searchMonitoring = (
  sanitationId: number,
  pagination: Record<string, unknown>,
): Promise<OptionsValue[]> => {
  return getMonitorings(sanitationId, pagination as Pagination).then((data) =>
    data.results.map((monitoring) => ({
      label: monitoring.number,
      value: monitoring.id,
    })),
  )
}

export const getMonitoring = (sanitationId: number, monitoringId: number): Promise<Monitoring> =>
  HTTP.get(SANITATION_MONITORING.DETAILS(sanitationId, monitoringId)).then(({ data }) => data)

export const updateMonitoring = (
  sanitationId: number | string,
  monitoringId: number,
  data: MonitoringData,
): Promise<AxiosResponse<Monitoring>> =>
  HTTP.put(SANITATION_MONITORING.DETAILS(sanitationId as number, monitoringId), data)

export const patchMonitoring = (
  sanitationId: number | string,
  monitoringId: number,
  data: any,
): Promise<AxiosResponse<Monitoring>> =>
  HTTP.patch(SANITATION_MONITORING.DETAILS(sanitationId as number, monitoringId), data)

export const deleteMonitoring = (
  sanitationId: number | string,
  monitoringId: number | string,
): Promise<Monitoring> =>
  HTTP.delete(SANITATION_MONITORING.DETAILS(sanitationId as number, monitoringId)).then(
    ({ data }) => data,
  )

export const commentMonitoring = async (
  value: string,
  sanitationId?: string,
  monitoringId?: string,
) => {
  try {
    if (sanitationId && monitoringId) {
      const {
        data: { comments },
      } = await patchMonitoring(sanitationId, parseInt(monitoringId), {
        comments: value,
      })
      return comments || ''
    }
  } catch (err: any) {
    parseServerError(err.response.data)
  }
}
