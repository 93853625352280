import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSanitationEquipmentMeasure } from '@/services/sanitationEquipmentMeasureServices'
import { EQUIPMENTS_CATEGORIES } from '@/constants'
import {
  buildEquipmentMeasureDictionary,
  sortMeasuresByLastValue,
  updateAirSample,
  updateMeasureValue,
} from '@/utils'
import { readEquipmentTypes } from '@/services/storageServices'

type FetchEquipmentMeasuresProps = {
  sanitationId: string | number
  monitoringId: string | number
  sanitation: Sanitation
}

const initialState: EquipmentMeasuresRecord[] = []
/**
 * Thunk action creator to fetch air filter measures asynchronously.
 * @param sanitationId The ID of the sanitation.
 * @param monitoringId The ID of the monitoring.
 * @param sanitation The sanitation object.
 */
export const fetchAirFiltersMeasures = createAsyncThunk(
  '/prefetch/airFiltersMeasure',
  async (
    { sanitationId, monitoringId, sanitation }: FetchEquipmentMeasuresProps,
    { rejectWithValue },
  ) => {
    try {
      const { results } = await getSanitationEquipmentMeasure(sanitationId, monitoringId, {
        equipment_type: EQUIPMENTS_CATEGORIES.AIR_FILTER,
      })
      return {
        data: results,
        sanitationId,
        monitoringId,
        sanitation,
        equipmentType: readEquipmentTypes().air_filter.id,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Slice for managing air filter measures state and actions.
 */
export const airFiltersMeasures = createSlice({
  name: 'airFiltersMeasures',
  initialState,
  reducers: {
    sortAirFiltersMeasuresByLastValue: sortMeasuresByLastValue,
    updateAirFiltersMeasuresParamValue: updateMeasureValue,
    updateAirFiltersAirSamples: updateAirSample,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAirFiltersMeasures.fulfilled,
      (
        _,
        action: PayloadAction<{
          data: SanitationEquipmentMeasure[]
          sanitationId: number | string
          monitoringId: number | string
          equipmentType: number
          sanitation: Sanitation
        }>,
      ) => {
        return buildEquipmentMeasureDictionary(action.payload)
      },
    )
  },
})

export const {
  sortAirFiltersMeasuresByLastValue,
  updateAirFiltersMeasuresParamValue,
  updateAirFiltersAirSamples,
} = airFiltersMeasures.actions

export default airFiltersMeasures.reducer
