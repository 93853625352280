import type { ConfigProviderProps, ThemeConfig } from 'antd/es/config-provider'
import i18next from 'i18next'

export const themeConfig: ThemeConfig = {
  token: {
    fontFamily: "'Prompt','DM Sans', sans-serif",
    fontSize: 14,
    colorTextBase: '#1d1d1f',
    colorPrimary: '#58a846',
    colorInfo: '#58a846',
    colorSuccess: '#55ff00',
    colorBgBase: '#ffffff',
    borderRadius: 14,
    colorError: 'rgb(248 113 113 / 1)',
    colorPrimaryBg: 'transparent',
  },
  components: {
    Segmented: {
      itemSelectedBg: 'rgba(88,168,70,.9)',
      itemHoverBg: 'rgba(88,168,70,.2)',
      itemSelectedColor: 'rgb(241 245 249)',
      itemHoverColor: 'rgba(88,168,70,.9)',
      borderRadius: 8,
    },
    Button: {
      controlHeight: 50,
      borderRadius: 12,
      fontWeight: 600,
    },
    Collapse: {
      motion: false,
      motionBase: 0,
    },
    Input: {
      controlHeight: 50,
      borderRadius: 12,
      colorBgContainer: 'rgb(241 245 259 / .9)',
      colorBorder: 'rgb(226 232 240 / .7)',
    },
    InputNumber: {
      controlHeight: 50,
      borderRadius: 12,
      colorBgContainer: 'rgb(241 245 259 / .9)',
      colorBorder: 'rgb(226 232 240 / .7)',
    },
    Carousel: {},
    Menu: {
      itemBorderRadius: 8,
      itemPaddingInline: 0,
      padding: 0,
      itemMarginInline: 4,
      itemHeight: 50,
      itemSelectedBg: 'rgba(88,168,70,.9)',
      itemSelectedColor: '#F5F8FB',
    },
    Select: {
      controlHeight: 50,
      controlHeightSM: 50,
      controlHeightLG: 50,
      borderRadius: 12,
      colorBgContainer: 'rgb(241 245 259 / .9)',
      optionSelectedBg: 'rgba(88 168 70 / .1)',
      optionSelectedColor: 'rgba(88 168 70 / 1)',
    },
    Notification: {
      fontWeightStrong: 600,
      fontSize: 18,
    },
    Message: {
      fontWeightStrong: 600,
      fontSize: 18,
    },
    DatePicker: {
      controlHeight: 50,
      colorBgContainer: 'rgb(241 245 259 / .9)',
    },
  },
}

export const formConfig: ConfigProviderProps['form'] = {
  validateMessages: {
    required: () => i18next.t('inputs.validation.required'),
  },
}
