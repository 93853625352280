import Icons from './Icons'

type SortOrder = 'ascend' | 'descend' | any

const orderIcons: {
  [key in SortOrder]: Icon
} = {
  ascend: Icons.ArrowUpIcon,
  descend: Icons.ArrowDownIcon,
}

export default function SorterIcons({ sortOrder }: { sortOrder: SortOrder }) {
  const IconComponent = orderIcons[sortOrder] || Icons.ArrowsUpDownIcon
  return <IconComponent className='h-4' />
}
