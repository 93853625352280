import notification from 'antd/es/notification'

/**
 * Display an error message.
 * @param error The error message to be displayed.
 */
export function warnError(error: string | string[]): void {
  if (error && (typeof error === 'string' || error.length > 0)) {
    if (Array.isArray(error)) error = error.join('|')
    notification.error({ message: error })
  }
}

/**
 * Parse server errors into an array of error objects.
 * @param serverErrors The server errors object.
 * @returns An array of error objects with name and errors properties.
 */
export function parseServerError(serverErrors: any): { name: string; errors: string[] }[] {
  warnError(serverErrors.error || serverErrors.detail)
  const errorsEntries: [string, string[]][] = Object?.entries?.(serverErrors)
  return errorsEntries.map((errorEntry: [string, string[]]) => ({
    name: errorEntry?.[0],
    errors: errorEntry?.[1],
  }))
}

/**
 * Convert server error object into an array.
 * @param err The server error object.
 * @returns An array of error messages.
 */
export function serverErrorToArray(err: any): string[] {
  const arr: string[] = []
  for (const key in err) {
    arr.push(err[key])
  }
  return arr
}

/**
 * Parse equipment creation error and display error messages.
 * @param err The error object containing equipment creation errors.
 */
export function parseEquipmentCreationError(err: any): void {
  const equipmentOperations = ['create', 'update', 'remove']
  const errors = err?.response?.data
  if (errors?.error) notification.error({ message: errors.error })
  const equipmentsErrors = errors?.equipments
  equipmentOperations.forEach((operation) => {
    if (equipmentsErrors?.[operation]) {
      // notification.destroy()
      notification.error({ message: equipmentsErrors[operation] })
    }
  })
}
